<style>
.privacidad_container{
       position: relative;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;
    color: black;
    margin-bottom: 100px;
    text-align: left;
}
.privacidad_container h1{
    color: #007bff;
}
.privacidad_container strong{
    color: black;
}
.privacidad_container ul{
    color: black;
}
</style>
<template>
    <div class="privacidad_container">
        <h1>Política de Privacidad</h1>
        <p>
    Introducción <br/><br/>
    Esta página está diseñada para ayudarle a entender por qué y cómo se utilizan los datos personales.<br/><br/>
    Por los datos personales que nos referimos a la información que se refiere a un individuo vivo y puede identificar o identificarse con ese individuo.<br/><br/>
    Linetor ofrece un servicio en línea de reserva de alojamiento, en exclusiva para los estudiantes universitarios, lo que les permite navegar a través de miles de propiedades en todo el mundo.<br/><br/>
    Para ello, tenemos relaciones con los inquilinos, propietarios, afiliados (referentes y / o las organizaciones asociadas) y nuestros proveedores. Procesamos los datos personales diferente para cada una de esas categorías de personas, y lo hacemos para diferentes propósitos.<br/><br/>
    1. ¿Quién opera Linetor y cómo puedo contactar con ellos?<br/><br/>
    El responsable del tratamiento de su información es Linetor Limited, una compañía con sede en Londres y Lisboa.<br/><br/>
    Estamos sociedad limitada registrada en Inglaterra con el número 8674633 compañía y nuestra dirección registrada es Linetor Limited, 107-111 Fleet Street, London, EC4A 2AB, Inglaterra.<br/><br/>
    Si usted tiene alguna pregunta o inquietud acerca de la información de esta página, o sobre lo que hacemos con los datos personales, usted debe escribir al correo electrónico <br/><br/>
    2. ¿Cómo utiliza Linetor mis datos personales como inquilino?<br/><br/>
    En resumen, utilizamos los datos personales relativos a los inquilinos a:
    Para permitir que los posibles inquilinos a buscar fácilmente pisos emparejan sus criterios en visitas de vuelta<br/><br/>
    Para proporcionar al propietario con información sobre un posible inquilino para ayudar a determinar si la elegibilidad del inquilino para alquilar su propiedad.
    Para proporcionar información de gestión a los socios Linetor' (universidades, por ejemplo, asociado o empleadores).<br/><br/>
    Para garantizar el inquilino sólo se ofrece propiedades que cumplen sus requisitos de acceso.<br/><br/>
    Para procesar pagos del inquilino.<br/><br/>
    Para el seguimiento de las reservas que no son capaces de proceder con, por ejemplo, porque el propietario rechaza la solicitud o hay un problema de disponibilidad.
    Para enviar marketing para usted.<br/><br/>
    Para ejecutar su negocio.<br/><br/>
    2.1 ¿Cómo Linetor utilizar los datos personales para permitir que los posibles inquilinos a buscar fácilmente propiedades?<br/><br/>
    A medida que use sus Linetor cuenta para reservar alojamiento que nos proporcionará la ubicación deseada, las fechas en las que desean residir en la propiedad y cuál será su rango de precio. Linetor mantendrá un registro de esta información y que serán capaces de presentar con propiedades sugeridas que considera que coincida con sus criterios.<br/><br/>
    2.1.1 ¿Qué datos personales se utiliza y dónde se obtiene a partir de?<br/><br/>
    Obtenemos su lugar preferido (s), su presupuesto y su período la primera vez que se inscribe para el servicio y / o cuando cambie su criterio de búsqueda en el servicio.<br/><br/>
    2.1.2 ¿Cuál es nuestra base legal?<br/><br/>
    Nuestra base legal para retener esta información es nuestro interés legítimo en asegurar que son capaces de repetir fácilmente su búsqueda de propiedades.<br/><br/>
    2.1.3 ¿A quién compartimos datos personales con para este fin?<br/><br/>
    No compartimos esta información con cualquier persona para este propósito, aparte de nuestros proveedores cuando sea necesario para que brinden su servicio a nosotros (ver el “quién compartimos datos personales con” más abajo).<br/><br/>
    2.2 ¿Cómo Linetor utilizar los datos personales para comprobar si el inquilino es elegible para alquilar una propiedad?<br/><br/>
    Los propietarios que tratan de dejar que sus propiedades tendrán sus propios criterios de elegibilidad discrecionales para los inquilinos.<br/><br/>
    2.2.1 ¿Qué datos personales se utiliza y dónde se obtiene a partir de?<br/><br/>
    Cuando usted envía su solicitud de reserva, solicitamos una biografía, su nacionalidad, edad, ocupación y sexo.<br/><br/>
    2.2.2 ¿Cuál es nuestra base legal?<br/><br/>
    Nuestra base legal es que este proceso es un paso preparatorio necesario para que el arrendador entrar en un contrato de arrendamiento con usted.<br/><br/>
    Sin esta información, el propietario puede no ser capaz de considerar la suscripción de un contrato de alquiler con usted.<br/><br/>
    2.2.3 ¿A quién compartimos datos personales con para este fin?<br/><br/>
    Esta información será compartida con el propietario relevante para que puedan decidir si usted cumple con los criterios de inquilinos.<br/><br/>
    También podemos compartir esta información con nuestros proveedores cuando sea necesario para que brinden su servicio a nosotros (ver el “quién compartimos datos personales con” más abajo).<br/><br/>
    2.3 ¿Cómo Linetor utilizar los datos personales para proporcionar información de gestión a sus socios?<br/><br/>
    Nuestro programa de afiliados permite a nuestros socios para referirse a los posibles inquilinos Linetor y potencialmente recibir un pago de comisión por cada inquilino que introducen que va a hacer una reserva. Por lo tanto, les proporcionan información relativa al número de personas a las que se han referido que han pasado a hacer una reserva con Linetor.<br/><br/>
    2.3.1 ¿Qué datos personales se utiliza y dónde se obtiene a partir de?<br/><br/>
    Al realizar su reserva, le pedimos su lugar de trabajo y / o estudio.<br/><br/>
    2.3.2 ¿Cuál es nuestra base legal?<br/><br/>
    Nuestra base legal es nuestro legítimo interés en proporcionar a nuestros afiliados con información precisa para apoyar el pago de la comisión que les corresponde.<br/><br/>
    2.3.3 ¿A quién compartimos datos personales con para este fin?<br/><br/>
    Sus datos personales no serán compartidos con los afiliados, sin embargo los datos agregados (por ejemplo, un número de estudiantes) que no identifica a usted será.
    También podemos compartir esta información con nuestros proveedores cuando sea necesario para que brinden su servicio a nosotros (ver el “quién compartimos datos personales con” más abajo).<br/><br/>
    2.4 ¿Cómo funciona Linetor utilizar los datos personales para asegurar que el inquilino sólo se ofrece propiedades que satisfagan sus necesidades de acceso?
    Entendemos que algunos de nuestros inquilinos pueden tener ciertos requisitos de acceso. Cuando así lo disponga, utilizamos estos datos para ayudar a encontrar una propiedad adecuada.<br/><br/>
    2.4.1 ¿Qué datos personales se utiliza y dónde se obtiene a partir de?<br/><br/>
    En el momento de la reserva, le pedimos que nos proporcione alguna información acerca de los requisitos de acceso que tiene.<br/><br/>
    2.4.2 ¿Cuál es nuestra base legal?<br/><br/>
    Nuestra base legal es que este es un paso preparatorio necesario que entrar en un contrato con el propietario correspondiente.<br/><br/>
    Sin esta información, el propietario podría no ser capaz de confirmar que la propiedad cumple con sus necesidades.<br/><br/>
    En la medida en que estos datos son una categoría especial de datos personales (en el que puede implicar una condición médica), dependemos es explícito consentimiento.<br/><br/>
    2.4.3 ¿A quién compartimos datos personales con para este fin?<br/><br/>
    Esta información será compartida con el propietario relevante para que puedan confirmar o no la propiedad conforme a sus necesidades.<br/><br/>
    También podemos compartir esta información con nuestros proveedores cuando sea necesario para que brinden su servicio a nosotros (ver el “quién compartimos datos personales con” más abajo).<br/><br/>
    2.5 ¿Cómo Linetor utilizar los datos personales para procesar pagos del arrendatario?<br/><br/>
    2.5.1 ¿Qué datos personales se utiliza y dónde se obtiene a partir de?<br/><br/>
    Cuando finaliza una reserva con nosotros, vamos a recoger datos de pago incluyen nombre, dirección de facturación, número de tarjeta de crédito, tarjeta de crédito y fecha de expiración de su número de tarjeta de valor de verificación.
    Linetor en sí no tiene acceso a estos datos, ya que se pasa directamente a nuestro proveedor de pago<br/><br/>
    2.5.2 ¿Cuál es nuestra base legal?<br/><br/>
    Nuestra base legal es que este tratamiento sea necesario para la ejecución del contrato entre el usuario y el propietario. Sin esta información no sería posible finalizar su reserva.<br/><br/>
    2.5.3 ¿A quién compartimos datos personales con para este fin?<br/><br/>
    Estos datos se envía directamente a nuestro procesador de pago, (por ejemplo PayPal o raya) para asegurar que los fondos se retiran de su cuenta y depositados en la nuestra, y luego del propietario.<br/><br/>
    2.6 ¿Cómo lo hace Linetor usar datos personales para el seguimiento de las reservas que han sido incapaces de proceder?<br/><br/>
    Dónde reserva no pasa por, por ejemplo, porque el propietario ya no tiene la disponibilidad, podríamos contactar con usted para ayudarle a encontrar una alternativa.<br/><br/>
    2.6.1 ¿Qué datos personales se utiliza y dónde se obtiene a partir de?<br/><br/>
    Si nos comunicamos con usted, podemos usar su nombre, número de teléfono, dirección de correo electrónico, ubicación, presupuesto, fechas preferidas, fecha de nacimiento, ocupación, sexo, detalles de nacionalidad, lugar de trabajo y / o estudio, biografía y detalles de los requisitos de acceso, en su caso.<br/><br/>
    Toda esta información se obtiene a partir de su solicitud original de la reserva.<br/><br/>
    2.6.2 ¿Cuál es nuestra base legal?<br/><br/>
    Nuestra base legal es nuestro interés legítimo en asegurar los inquilinos son capaces de encontrar una propiedad alternativa cuando una reserva se concreta.<br/><br/>
    2.6.3 ¿A quién compartimos datos personales con para este fin?<br/><br/>
    No compartimos estos datos para este propósito, aparte de nuestros proveedores cuando sea necesario para que brinden su servicio a nosotros (ver el “quién compartimos datos personales con” más abajo).<br/><br/>
    2.7 ¿Cómo lo hace Linetor utilizar los datos personales para la comercialización?<br/><br/>
    Por favor, consulte la sección “¿Cómo se Linetor utilizar los datos personales para la comercialización” a continuación.<br/><br/>
    2.8 ¿Cómo Linetor otra manera utilizar los datos personales en el funcionamiento de su negocio?<br/><br/>
    Por favor, consulte la sección “¿Cómo se Linetor otra manera utilizar los datos personales en el funcionamiento de su negocio” más abajo.<br/><br/>
    3 ¿Cómo se utiliza mi Linetor datos personales como propietario?<br/><br/>
    En resumen utilizamos los datos personales relativos a los propietarios:<br/><br/>
    Para procesar los pagos a los propietarios<br/><br/>
    Para administrar las reservas, incluyendo la inserción de anuncio para la habitación del propietario, lista que cuando está disponible, las reservas de procesamiento, etc.
    para verificar la exactitud de la información proporcionada por el propietario.
    Para enviar marketing para usted.<br/><br/>
    Para ejecutar su negocio.<br/><br/>
    Puede encontrar más información sobre cómo hacerlo haciendo clic en el título correspondiente a continuación.<br/><br/>
    3.1 ¿Cómo se Linetor utilizar los datos personales para procesar los pagos a los propietarios?<br/><br/>
    Cuando un inquilino nos paga por una reserva, pagamos las cantidades arrendador debe, menos nuestros honorarios, por transferencia bancaria.<br/><br/>
    3.1.1 ¿Qué datos personales se utiliza y dónde se obtiene a partir de?
    Utilizamos los datos bancarios y la dirección de facturación proporcionada cuando se inscribió a Linetor como propietario.<br/><br/>
    3.1.2 ¿Cuál es nuestra base legal?<br/><br/>
    Nuestra base legal es que este tratamiento sea necesario para llevar a cabo nuestro contrato con usted. Sin esta información no sería capaz de pagar.<br/><br/>
    3.1.3 ¿A quién compartimos datos personales con para este fin?<br/><br/>
    Vamos a compartir esta información con nuestro banco con el fin de procesar el dinero adeudado a usted.<br/><br/>
    También podemos compartir esta información con nuestros proveedores cuando sea necesario para que brinden su servicio a nosotros (ver el “quién compartimos datos personales con” más abajo).<br/><br/>
    3.2 ¿Cómo Linetor utilizar los datos personales para administrar las reservas?<br/><br/>
    3.2.1 ¿Qué datos personales se utiliza y dónde se obtiene a partir de?<br/><br/>
    Vamos a utilizar los datos proporcionados por usted al crear su cuenta y / o ha agregado una lista de propiedades, incluyendo: nombre, número de teléfono, dirección, sala / detalles de la propiedad, servicios, reglas de la casa, el género, la edad, detalles de los miembros de la familia , detalles de mascotas, si usted es un propietario residente, las preferencias de los inquilinos, la disponibilidad de bienes y cualesquiera imágenes o texto libre de ser facilitados por usted.
    3.2.2 ¿Cuál es nuestra base legal?<br/><br/>
    Nuestra base legal es que este tratamiento sea necesario para llevar a cabo nuestro contrato con usted. Sin esta información no sería capaz de publicar su listado de propiedad.<br/><br/>
    3.2.3 ¿A quién compartimos datos personales con para este fin?<br/><br/>
    No compartimos estos datos para este propósito, aparte de nuestros proveedores cuando sea necesario para que brinden su servicio a nosotros (ver el “quién compartimos datos personales con” más abajo).<br/><br/>
    3.3 ¿Cómo Linetor utilizar los datos personales para verificar la exactitud de la información proporcionada por el propietario?<br/><br/>
    Podemos, en el que organizar una visita para tomar fotografías de su propiedad, proporcionar datos personales a los fotógrafos para que puedan verificar la exactitud de su establecimiento.<br/><br/>
    3.3.1 ¿Qué datos personales se utiliza y dónde se obtiene a partir de?<br/><br/>
    Vamos a utilizar los datos proporcionados por usted al crear su cuenta y / o ha agregado la lista de propiedades relevantes, incluyendo: nombre, número de teléfono, dirección, sala / detalles de la propiedad, servicios, reglas de la casa, el género, la edad, el miembro de la familia detalles, detalles de mascotas, si usted es un propietario residente, las preferencias de los inquilinos, la disponibilidad de bienes y cualesquiera imágenes o texto libre de ser facilitados por usted.<br/><br/>
    3.3.2 ¿Cuál es nuestra base legal?<br/><br/>
    Nuestra base legal es nuestro interés legítimo en la verificación de que los anuncios que aparecen en Linetor son exactos.<br/><br/>
    3.3.3 ¿A quién compartimos datos personales con para este fin?<br/><br/>
    Compartimos estos datos con el fotógrafo que visita su propiedad.<br/><br/>
    3.4 ¿Cómo lo hace Linetor utilizar los datos personales para la comercialización?
    Por favor, consulte la sección “¿Cómo se Linetor utilizar los datos personales para la comercialización” a continuación.
   <br/><br/>
    3.5 ¿Cómo Linetor otra manera utilizar los datos personales en el funcionamiento de su negocio?<br/><br/>
    Por favor, consulte la sección “¿Cómo se Linetor otra manera utilizar los datos personales en el funcionamiento de su negocio” más abajo.
    <br/><br/>4 ¿Cómo se utiliza mi Linetor datos personales como afiliado?<br/><br/>
    Hemos utilizado los datos personales relativos a los afiliados:
    Para administrar su estado afiliado
    Para procesar los pagos a los afiliados.
    Para enviar marketing para usted.
    Para ejecutar su negocio.
    Puede encontrar más información sobre cómo hacerlo haciendo clic en el título correspondiente a continuación.
    <br/><br/>4.1 ¿Cómo lo hace Linetor utilizar los datos personales para administrar su estado de afiliado?
    Cuando se registra como un afiliado, utilizaremos sus datos personales para administrar su estado de afiliado, incluso para darle acceso a nuestros referidos portal de afiliados y la pista que realice.
    <br/><br/>4.1.1 ¿Qué datos personales se utiliza y dónde se obtiene a partir de?<br/><br/>
    Utilizamos los datos que nos proporciona cuando se inscribe como afiliado, y alguna información que generamos mediante sus códigos de seguimiento único, por ejemplo, el número de posibles inquilinos que se han referido y cuántos han ido a las reservas de maquillaje.
    <br/><br/>4.1.2 ¿Cuál es nuestra base legal?<br/><br/>
    Nuestra base legal es que este tratamiento sea necesario para llevar a cabo nuestro contrato con usted. Sin esta información no sería capaz de manejar su relación de afiliación con nosotros.
    <br/><br/>4.1.3 ¿A quién compartimos datos personales con para este fin?<br/><br/>
    No compartimos estos datos para este propósito, aparte de nuestros proveedores cuando sea necesario para que brinden su servicio a nosotros (ver el “quién compartimos datos personales con” más abajo).
    <br/><br/>4.2 ¿Cómo lo hace Linetor utilizar los datos personales para procesar los pagos a los afiliados?<br/><br/>
    Cuando un inquilino que se ha referido hace una reserva, podemos hacer un pago a usted que vamos a hacer a través de transferencia bancaria.
    <br/><br/>4.2.1 ¿Qué datos personales se utiliza y dónde se obtiene a partir de?<br/><br/>
    Utilizamos los datos bancarios y la dirección de facturación proporcionada cuando se inscribió como afiliado.
    <br/><br/>4.2.2 ¿Cuál es nuestra base legal?<br/><br/>
    Nuestra base legal es que este tratamiento sea necesario para llevar a cabo nuestro contrato con usted. Sin esta información no sería capaz de pagar.
    <br/><br/>4.2.3 ¿A quién compartimos datos personales con para este fin?<br/><br/>
    Vamos a compartir esta información con nuestro banco con el fin de procesar el dinero adeudado a usted.
    También podemos compartir esta información con nuestros proveedores cuando sea necesario para que brinden su servicio a nosotros (ver el “quién compartimos datos personales con” más abajo).
    <br/><br/>4.3 ¿Cómo lo hace Linetor utilizar los datos personales para la comercialización?<br/><br/>
    Por favor, consulte la sección “¿Cómo se Linetor utilizar los datos personales para la comercialización” a continuación.
    <br/><br/>4.4 ¿Cómo Linetor otra manera utilizar los datos personales en el funcionamiento de su negocio?<br/><br/>
    Por favor, consulte la sección “¿Cómo se Linetor otra manera utilizar los datos personales en el funcionamiento de su negocio” más abajo.
    <br/><br/>5 ¿Cómo Linetor utilizar los datos personales para la comercialización?<br/><br/>
    En resumen, independientemente de si usted es un propietario, inquilino o afiliado, utilizamos los datos personales:
    Para la comercialización de nuestro servicio a los inquilinos, los propietarios y los afiliados a través de correo electrónico, incluyendo el envío de nuestros boletines
    Para adaptar la comercialización a posibles intereses del destinatario.
    Para comercializar sus servicios a los inquilinos, propietarios y afiliados a través del teléfono.
    Para orientar la publicidad gráfica a determinados datos demográficos de la persona en las plataformas de Facebook publicitarios (por ejemplo, Google).
    Para orientar anuncios de visualización en otros sitios web a personas en particular sobre la base de las páginas de nuestro sitio web que se han leído (re-targeting).
    Para crear “mirar-uno-como” audiencias en las redes de publicidad que utilizamos.
    Puede encontrar más información sobre cómo hacerlo haciendo clic en el título correspondiente a continuación.
    <br/><br/>5.1 ¿Cómo funciona Linetor utilizar los datos personales para comercializar sus servicios a los inquilinos, los propietarios y los afiliados a través de correo electrónico, incluyendo el envío de su boletín de noticias?<br/><br/>
    <br/><br/>5.1.1 ¿Qué datos personales se utiliza y dónde se obtiene a partir de?<br/><br/>
    Cuando usted es un inquilino o propietario, se utiliza el nombre y dirección de correo electrónico que proporcionó cuando se registró con Linetor.
    Cuando usted es un afiliado prospectivo, se utiliza el nombre y dirección de correo electrónico que proporcionó cuando se ha completado un formulario de consulta.
    <br/><br/>5.1.2 ¿Cuál es nuestra base legal?<br/><br/>
    Cuando usted es un inquilino o propietario, nuestra base legal es nuestro interés legítimo en la comercialización de nuestro servicio. Sólo se comercializará servicios similares y siempre ofrecer la oportunidad para que usted pueda cancelar la suscripción en el momento de la recolección de su dirección de correo electrónico, y en cada correo electrónico que envíe a partir de entonces.
    Cuando usted es un afiliado prospectivo, contamos con su consentimiento.
    <br/><br/>5.1.3 ¿A quién compartimos datos personales con para este fin?<br/><br/>
    No compartimos estos datos para este propósito, aparte de nuestros proveedores cuando sea necesario para que brinden su servicio a nosotros (ver el “quién compartimos datos personales con” más abajo).
    <br/><br/>5.2 ¿Cómo Linetor utilizar los datos personales para adaptar la comercialización a posibles intereses del destinatario?<br/><br/>
    Vamos a utilizar sus datos personales para enviar determinadas campañas de marketing para usted, basado en atributos que tiene. Por ejemplo, si usted es un inquilino que podría enviar una campaña de marketing en particular a un subconjunto de nuestros inquilinos registrados en función de sus preferencias de ubicación y presupuesto.
    <br/><br/>5.2.1 ¿Qué datos personales se utiliza y dónde se obtiene a partir de?<br/><br/>
    Podemos utilizar cualquiera de la información que tenemos sobre usted para este propósito, pero más típicamente nos dirigimos a nuestro marketing basado en la ubicación que desee (si el inquilino), la ubicación de sus propiedades (si el propietario) o su ubicación ( Si un afiliado).
    <br/><br/>5.2.2 ¿Cuál es nuestra base legal?<br/><br/>
    Nuestra base legal es nuestro interés legítimo en asegurar nuestra comercialización se entrega a aquellas personas con más probabilidades de seguir adelante con nuestros servicios, por lo tanto reduciendo el costo de nuestra conversión cuando la comercialización de nuestro servicio.
    <br/><br/>5.2.3 ¿A quién compartimos datos personales con para este fin?<br/><br/>
    No compartimos estos datos para este propósito, aparte de nuestros proveedores cuando sea necesario para que brinden su servicio a nosotros (ver el “quién compartimos datos personales con” más abajo).
    <br/><br/>5.3 ¿Cómo funciona Linetor utilizar los datos personales para comercializar sus servicios a los inquilinos, los propietarios y los afiliados a través del teléfono?<br/><br/>
    <br/><br/>5.3.1 ¿Qué datos personales se utiliza y dónde se obtiene a partir de?<br/><br/>
    Si usted es un inquilino, arrendador o un afiliado actual, se utiliza el nombre y número de teléfono que proporcionó cuando se registró con Linetor.
    Si usted es un afiliado prospectivo, se utiliza el nombre y número de teléfono que (a) usted nos ha dado
    <br/><br/>5.3.2 ¿Cuál es nuestra base legal?<br/><br/>
    Nuestra base legal es nuestro interés legítimo en la comercialización de nuestro servicio. Siempre vamos a la pantalla de su teléfono contra los servicios de preferencias de teléfono pertinentes.
    <br/><br/>5.3.3 ¿A quién compartimos datos personales con para este fin?<br/><br/>
    No compartimos estos datos para este propósito, aparte de nuestros proveedores cuando sea necesario para que brinden su servicio a nosotros (ver el “quién compartimos datos personales con” más abajo).
    <br/><br/>5.4 ¿Cómo Linetor utilizar los datos personales para mostrar publicidad para determinados datos demográficos sobre plataformas de publicidad?<br/><br/>
    plataformas de publicidad como Google y Facebook permiten alcanzar nuestros anuncios a determinados tipos de usuario, por ejemplo, en función de su edad, ubicación y sus intereses.
    <br/><br/>5.4.1 ¿Qué datos personales se utiliza y dónde se obtiene a partir de?<br/><br/>
    Los anuncios están orientados en función de los datos personales en poder de la red de publicidad relevante acerca de usted.
    Las redes de publicidad no dan a acceder a estos datos, sólo nos dan la posibilidad de orientar nuestros anuncios basándose en las características de un grupo como el grupo de edad o ubicación.
    <br/><br/>5.4.2 ¿Cuál es nuestra base legal?<br/><br/>
    Nuestra base legal es nuestro interés legítimo en asegurar nuestra comercialización se entrega a aquellas personas con más probabilidades de seguir adelante con nuestro servicio, por lo tanto reduciendo el costo de nuestra conversión cuando la comercialización de nuestro servicio.
    Usted puede oponerse a este procesamiento cambiando su configuración de privacidad en la red de publicidad relevante.
    <br/><br/>5.4.3 ¿A quién compartimos datos personales con para este fin?<br/><br/>
    Linetor no tiene acceso a su información demográfica, esto está en poder de la plataforma de publicidad relevante (Facebook o Google).
    <br/><br/>5.5 ¿Cómo lo hace Linetor utilizar los datos personales para mostrar anuncios a varios temas en particular de datos?<br/><br/>
    A medida que utiliza nuestro servicio, nuestras redes de publicidad (Google y Facebook) hará un seguimiento y registrar las páginas que visita en nuestro sitio web a través de un píxel de seguimiento o una galleta para que podamos hacer las redes de publicidad para mostrar anuncios basados   en las páginas que ha visitado en nuestro sitio web.
    Por ejemplo, podríamos preguntar Facebook para mostrar un anuncio en particular a los usuarios que recientemente han buscado alojamiento en una ciudad en particular en nuestra página web.
    <br/><br/>5.5.1 ¿Qué datos personales se utiliza y dónde se obtiene a partir de?<br/><br/>
    Vamos a incluir código dentro de nuestra página web proporcionada por las redes de publicidad que, o bien coloca una cookie en su dispositivo, o que incluye una imagen invisible, los cuales permiten que la red de publicidad para mostrar anuncios a usted, basado en las páginas de nuestro sitio web que ha visitado.
    <br/><br/>5.5.2 ¿Cuál es nuestra base legal?<br/><br/>
    Nuestra base legal es el consentimiento.
    Usted puede retirar su consentimiento en cualquier momento poniéndose en contacto con nosotros, o cambiando la configuración de privacidad en la red de publicidad relevante.
    <br/><br/>5.5.3 ¿A quién compartimos datos personales con para este fin?<br/><br/>
    A medida que la galleta o el seguimiento de píxeles se establece utilizando el código de las redes de publicidad, esta técnica ha sido compartida directamente con ellos, y Linetor no tiene acceso directo a la misma.
    <br/><br/>5.6 ¿Cómo Linetor utilizar los datos personales para crear “mirar-uno-como” el público en redes de publicidad?<br/><br/>
    redes de publicidad
    Linetor’ (por ejemplo, Google y Facebook) lo permiten cargar las direcciones de correo electrónico del cliente, que la red de publicidad coincidirá con su propia base de datos.
    La red de publicidad se verá en las características demográficas de las personas sobre la base de los datos que contiene, y permitir Linetor para mostrar anuncios a las personas que comparten características similares.
    Linetor siempre va a pedir a la plataforma de publicidad para excluir a las personas que se utilizan para crear la audiencia “mirar-uno-como” que se muestre un anuncio dirigido de esta manera.
    <br/><br/>5.6.1 ¿Qué datos personales se utiliza y dónde se obtiene a partir de?<br/><br/>
    Vamos a utilizar la dirección de correo electrónico que nos proporcionó al registrarse en Linetor.
    <br/><br/>5.6.2 ¿Cuál es nuestra base legal?<br/><br/>
    Nuestra base legal es nuestro interés legítimo en asegurar nuestra comercialización se entrega a aquellas personas con más probabilidades de seguir adelante con una reserva, por lo tanto reduciendo el costo de nuestra conversión cuando la comercialización de nuestro servicio.
    Usted puede oponerse a este procesamiento cambiando su configuración de privacidad en la red de publicidad relevante.
    <br/><br/>5.6.3 ¿A quién compartimos datos personales con para este fin?<br/><br/>
    Estos datos se comparten con nuestras redes de publicidad (Google y Facebook) para este propósito.
    <br/><br/>6 ¿Cómo se utiliza mi Linetor datos personales como proveedor?<br/><br/>
    <br/><br/>6.1 ¿Cómo funciona Linetor utilizar los datos personales para adquirir y gestionar los servicios proporcionados por los proveedores?<br/><br/>
    <br/><br/>6.1.1 ¿Qué datos personales se utiliza y dónde se obtiene a partir de?<br/><br/>
    Podemos utilizar todos los datos personales que recibimos de usted, por ejemplo el nombre, dirección, dirección de correo electrónico, número de teléfono y, a menos que un empleado de proveedores corporativos: detalles de la cuenta bancaria, los detalles de los pagos efectuados y recibidos y contratos
    También vamos a generar datos personales sobre usted, por ejemplo, la correspondencia interna sobre su rendimiento.
    <br/><br/>6.1.2 ¿Cuál es nuestra base legal?<br/><br/>
    Nuestra base legal es nuestro legítimo interés en la gestión de nuestra relación con usted como proveedor.
    <br/><br/>6.1.3 ¿A quién compartimos datos personales con para este fin?<br/><br/>
    No compartimos estos datos para este propósito, aparte de nuestros proveedores cuando sea necesario para que brinden su servicio a nosotros (ver el “quién compartimos datos personales con” más abajo).
    <br/><br/>7 ¿Cómo se utiliza mi Linetor datos personales como un solicitante de empleo?<br/><br/>
    Si usted solicita un trabajo en Linetor, podemos utilizar su información personal:
    corresponder con usted en relación con su solicitud
    Para evaluar su idoneidad para el papel que ha solicitado.
    Para confirmar su elegibilidad para trabajar.
    Puede encontrar más información sobre cómo hacerlo haciendo clic en el título correspondiente a continuación.
    <br/><br/>7.1 ¿Cómo se corresponden Linetor utilizar los datos personales con usted en relación con su aplicación?<br/><br/>
    <br/><br/>7.1.1 ¿Qué datos personales se utiliza y dónde se obtiene a partir de?<br/><br/>
    Vamos a mantener correos electrónicos, cartas y registros de conversaciones telefónicas enviados / realizado y recibido de usted.
    <br/><br/>7.1.2 ¿Cuál es nuestra base legal?<br/><br/>
    Nuestra base legal es que este procesamiento es un paso preparatorio necesario para la celebración de un contrato de trabajo con usted.
    <br/><br/>7.1.3 ¿A quién compartimos datos personales con para este fin?<br/><br/>
    Esta información no tiene destinatario. Vamos a conservar esta información.
    También podemos compartir esta información con nuestros proveedores cuando sea necesario para que brinden su servicio a nosotros (ver el “quién compartimos datos personales con” más abajo).
    <br/><br/>7.2 ¿Cómo funciona Linetor utilizar los datos personales para evaluar su idoneidad para el papel que ha solicitado?<br/><br/>
    Linetor mantiene un registro de su aplicación, incluyendo su propia aplicación, notas de entrevistas, referencias y otra información recopilada durante el proceso de solicitud con el fin de considerar su aplicación.
    <br/><br/>7.2.1 ¿Qué datos personales se utiliza y dónde se obtiene a partir de?<br/><br/>
    Linetor mantiene un registro de su aplicación, incluyendo su propia aplicación (obtenida de usted oa través de un agente, notas de la entrevista (creados por nuestro personal), referencias (obtenidos a partir de los árbitros que figuran en su aplicación) y otra información recogida durante el proceso de aplicación.
    <br/><br/>7.2.2 ¿Cuál es nuestra base legal?<br/><br/>
    El tratamiento de estos datos personales es un paso preparatorio necesario, posiblemente, entrar en un contrato de trabajo con usted. Sin este proceso, que no sería capaz de continuar con su aplicación.
    Cuando el archivo de aplicación contiene las categorías especiales de datos personales, nuestra base legal es explícito consentimiento.
    <br/><br/>7.2.3 ¿A quién compartimos datos personales con para este fin?<br/><br/>
    Podemos compartir esta información en la medida necesaria para solicitar referencias de los árbitros que enumeró en su aplicación.
    También podemos compartir esta información con nuestros proveedores cuando sea necesario para que brinden su servicio a nosotros (ver el “quién compartimos datos personales con” más abajo).
    <br/><br/>7.3 ¿Cómo lo hace Linetor utilizar los datos personales para confirmar su elegibilidad para trabajar?<br/><br/>
    <br/><br/>7.3.1 ¿Qué datos personales se utiliza y dónde se obtiene a partir de?<br/><br/>
    Una fotocopia de su documento de identificación, incluyendo una visa si es relevante, tomada de un original que nos ha proporcionado.
    <br/><br/>7.3.2 ¿Cuál es nuestra base legal?<br/><br/>
    Nuestra base legal es que el tratamiento sea necesario para cumplir con las obligaciones legales impuestas a nosotros.
    En la medida en que los datos incluyen categorías especiales de datos, nuestra base legal es que el tratamiento sea necesario para cumplir con una obligación legal en el contexto de la ley de empleo.
    <br/><br/>7.3.3 ¿A quién compartimos datos personales con para este fin?<br/><br/>
    No compartimos lo general estos datos para este propósito a menos solicitado por una autoridad pertinente y con nuestros proveedores cuando sea necesario para que brinden su servicio a nosotros (ver el “quién compartimos datos personales con” más abajo).
    <br/><br/>8 ¿Cómo Linetor otra manera utilizar los datos personales en el funcionamiento de su negocio?<br/><br/>
    En resumen, independientemente de si usted es un propietario, inquilino o afiliado, utilizamos los datos personales:
    Para permitir que los inquilinos y propietarios para iniciar sesión en el sitio web Linetor
    Para informar al inquilino o propietario (como puede ser el caso) de la identidad de la otra parte, por lo que cada uno puede confirmar la identidad de la otra en el registro de entrada.
    Para gestionar las reservas, incluyendo mantener el arrendatario / arrendador al día sobre sus reservas.
    Para obtener retroalimentación de los inquilinos y propietarios sobre el servicio Linetor.
    Para hacer los inquilinos, propietarios y afiliados para completar encuestas con fines de investigación de mercado.
    Para mantener registros financieros.
    Para capacitar a nuestro personal.
    Para atender las consultas y quejas.
    Para presentar o defender demandas legales.
    Puede encontrar más información sobre cómo hacerlo haciendo clic en el título correspondiente a continuación.
    <br/><br/>8.1 ¿Cómo funciona Linetor utilizar los datos personales para que pueda iniciar sesión en el sitio web Linetor?<br/><br/>
    <br/><br/>8.1.1 ¿Qué datos personales se utiliza y dónde se obtiene a partir de?<br/><br/>
    Vamos a utilizar la dirección de correo electrónico y la contraseña que proporcionó cuando se registró para Linetor, o, alternativamente, si se ha registrado a través de una cuenta de medios de comunicación social, vamos a utilizar el token de autorización que recibimos de que la plataforma de medios sociales.
    <br/><br/>8.1.2 ¿Cuál es nuestra base legal?<br/><br/>
    Nuestra base legal es nuestro interés legítimo en la autenticación de los usuarios.
    <br/><br/>8.1.3 ¿A quién compartimos datos personales con para este fin?<br/><br/>
    No compartimos estos datos para este propósito, aparte de nuestros proveedores cuando sea necesario para que brinden su servicio a nosotros (ver el “quién compartimos datos personales con” más abajo).
    <br/><br/>8.2 ¿Cómo Linetor utilizar los datos personales para que pueda verificar la identidad de que el propietario o el inquilino?<br/><br/>
    <br/><br/>8.2.1 ¿Qué datos personales se utiliza y dónde se obtiene a partir de?<br/><br/>
    Vamos a utilizar el nombre que nos proporcionó cuando se registró para Linetor.
    <br/><br/>8.2.2 ¿Cuál es nuestra base legal?<br/><br/>
    Nuestra base legal es nuestro, el interés legítimo de los propietarios para garantizar el inquilino y el propietario del inquilino y están tratando con la persona correcta en el registro.
    <br/><br/>8.2.3 ¿A quién compartimos datos personales con para este fin?<br/><br/>
    Vamos a compartir el nombre del arrendatario con el propietario, y el nombre del propietario con el inquilino.
    También podemos compartir esta información con nuestros proveedores cuando sea necesario para que brinden su servicio a nosotros (ver el “quién compartimos datos personales con” más abajo).
    <br/><br/>8.3 ¿Cómo funciona Linetor usar datos personales para el seguimiento de las reservas y los listados abandonados?<br/><br/>
    <br/><br/>8.3.1 ¿Qué datos personales se utiliza y dónde se obtiene a partir de?<br/><br/>
    Cuando sólo completar parcialmente una reserva o para publicar anuncios.
    <br/><br/>8.3.2 ¿Cuál es nuestra base legal?<br/><br/>
    Nuestra base legal es nuestro interés legítimo en la reducción de la & quot
    <br/><br/>8.3.3 ¿A quién compartimos datos personales con para este fin?<br/><br/>
    No compartimos estos datos para este propósito, aparte de nuestros proveedores cuando sea necesario para que brinden su servicio a nosotros (ver el “quién compartimos datos personales con” más abajo).
    <br/><br/>8.4 ¿Cómo Linetor utilizar los datos personales para obtener retroalimentación de los inquilinos y propietarios sobre sus servicios?<br/><br/>
    Utilizamos un socio de terceros, Confianza piloto, para recoger opiniones acerca de nuestro servicio.
    <br/><br/>8.4.1 ¿Qué datos personales se utiliza y dónde se obtiene a partir de?<br/><br/>
    Vamos a utilizar el nombre y dirección de correo electrónico que nos ha facilitado al registrarse en Linetor.
    <br/><br/>8.4.2 ¿Cuál es nuestra base legal?<br/><br/>
    Nuestra base legal es nuestro legítimo interés en recibir información para mejorar nuestro servicio.
    <br/><br/>8.4.3 ¿A quién compartimos datos personales con para este fin?<br/><br/>
    Linetor compartirá esta información con Trustpilot, que le enviará una invitación para completar una revisión en nuestro nombre.
    <br/><br/>8.5 ¿Cómo funciona Linetor usar datos personales para pedir a los inquilinos, propietarios y afiliados para completar encuestas con fines de investigación de mercado?<br/><br/>
    <br/><br/>8.5.1 ¿Qué datos personales se utiliza y dónde se obtiene a partir de?<br/><br/>
    Usamos el nombre y dirección de correo electrónico proporcionada a nosotros cuando se inscribió en Linetor.
    <br/><br/>8.5.2 ¿Cuál es nuestra base legal?<br/><br/>
    Nuestra base legal es nuestro interés legítimo de obtener los comentarios de nuestros usuarios con el fin de mejorar el servicio que ofrecemos.
    <br/><br/>8.5.3 ¿A quién compartimos datos personales con para este fin?<br/><br/>
    Linetor compartirá esta información con su socio encuesta a los clientes, que le enviará una invitación para completar una revisión en nuestro nombre.
    Nuestro socio encuesta a continuación, recoger las respuestas y compartir información agregada con nosotros que no le identifique.
    <br/><br/>8.6 ¿Cómo Linetor utilizar los datos personales para mantener sus registros financieros?<br/><br/>
    <br/><br/>8.6.1 ¿Qué datos personales se utiliza y dónde se obtiene a partir de?<br/><br/>
    Generamos registros de los pagos realizados desde y hacia usted.
    <br/><br/>8.6.2 ¿Cuál es nuestra base legal?<br/><br/>
    Nuestra base legal es que se requiere el procesamiento de cumplir con las obligaciones legales impuestos por la ley y por agencias gubernamentales como HMRC.
    <br/><br/>8.6.3 ¿A quién compartimos datos personales con para este fin?<br/><br/>
    Podemos compartir esta información con HMRC, pero por lo demás sólo compartiremos estos datos con nuestros proveedores cuando sea necesario para que brinden su servicio a nosotros (ver el “quién compartimos datos personales con” más abajo).
    <br/><br/>8.7 ¿Cómo Linetor utilizar los datos personales para entrenar a su personal?<br/><br/>
    <br/><br/>8.7.1 ¿Qué datos personales se utiliza y dónde se obtiene a partir de?<br/><br/>
    Registramos en entrante y saliente unido a las llamadas telefónicas.
    <br/><br/>8.7.2 ¿Cuál es nuestra base legal?<br/><br/>
    Nuestra base legal es nuestro interés legítimo en la formación de nuestro personal. Siempre vamos a garantizar una grabación telefónica no tiene información sensible dentro antes de usarla para fines de entrenamiento.
    <br/><br/>8.7.2 ¿A quién compartimos datos personales con para este fin?<br/><br/>
    No compartimos estos datos para este propósito, aparte de nuestros proveedores cuando sea necesario para que brinden su servicio a nosotros (ver el “quién compartimos datos personales con” más abajo).
    <br/><br/>8.8 ¿Cómo Linetor utilizar los datos personales para hacer frente a las consultas y quejas?<br/><br/>
    <br/><br/>8.8.1 ¿Qué datos personales se utiliza y dónde se obtiene a partir de?<br/><br/>
    Podemos utilizar los datos personales que tenemos de usted para hacer frente a las consultas y quejas, pero por lo general esto incluirá detalles de la reserva, la correspondencia entre nosotros, y la información que genere en la investigación de su consulta o queja.
    <br/><br/>8.8.2 ¿Cuál es nuestra base legal?<br/><br/>
    Nuestra base legal es nuestro interés legítimo en hacer consultas y quejas se resuelven.
    Sin este proceso, que podría no ser capaz de atender su consulta o queja.
    <br/><br/>8.8.3 ¿A quién compartimos datos personales con para este fin?<br/><br/>
    No compartimos estos datos para este propósito, aparte de nuestros proveedores cuando sea necesario para que brinden su servicio a nosotros (ver el “quién compartimos datos personales con” más abajo).
    <br/><br/>8.9 ¿Cómo funciona Linetor usar datos personales para presentar o defender demandas legales?<br/><br/>
    <br/><br/>8.9.1 ¿Qué datos personales se utiliza y dónde se obtiene a partir de?<br/><br/>
    Podemos utilizar cualquiera de los datos personales que tenemos de usted para presentar o defender demandas legales, incluida la correspondencia, grabaciones telefónicas, registros de pago y los detalles de los servicios que suministramos a usted o que lo provisto a nosotros.
    <br/><br/>8.9.2 ¿Cuál es nuestra base legal?<br/><br/>
    Nuestra base legal es nuestro interés legítimo para interponer y la defensa de posibles demandas legales.
    <br/><br/>8.9.3 ¿A quién compartimos datos personales con para este fin?<br/><br/>
    Podemos compartir estos datos con los asesores externos a efectos de obtener el asesoramiento de ellas en relación con una reclamación.
    <br/><br/>9 ¿Cuánto tiempo se mantienen Linetor datos personales para?<br/><br/>
    <br/><br/>10 ¿Quién Linetor compartir datos personales con?<br/><br/>
    A nivel interno, Linetor podrá conceder el acceso a los datos personales para los fines de la formación del personal para que puedan llevar a cabo su función.
    Externamente, Linetor puede compartir los datos personales con terceros para fines específicos, según lo establecido en los apartados anteriores. También pueden, de vez en cuando compartiremos los datos personales con las siguientes categorías de destinatarios:<br/><br/>
    sus proveedores de servicios, por ejemplo:<br/><br/>
    las empresas que alojar y administrar nuestra infraestructura de TI
    empresas que nos proporcionan los sistemas de TI basada en la nube, como nuestro sistema de CRM o sistema de entrega de correo electrónico
    sus asesores externos, por ejemplo TI consultores, contadores y abogados.
    Cuando compartimos datos personales con proveedores de servicios siempre vamos a asegurar que el proveedor de servicios se compromete por contrato a utilizar únicamente los datos personales de acuerdo con las instrucciones Linetor’ y el derecho de protección de datos
    sus reguladores, la policía, servicios de inteligencia y otras autoridades gubernamentales, en los que nos obligan a hacerlo
    potenciales compradores de los inversores o en nuestro negocio cuando sea necesario en relación con un examen previo, en virtud de acuerdos de confidencialidad adecuados (como NDA).
    <br/><br/>11 de transferencia 11 no Linetor datos personales fuera de la Zona Económica Europea?<br/><br/>
    Linetor generalmente almacena y procesa los datos personales dentro del Espacio Económico Europeo.
    Sin embargo, en algunas circunstancias, sus proveedores de servicios pueden transferir datos personales fuera del Área Económica Europea.
    Cuando lo hacen, Linetor’ exige a sus proveedores de servicios para hacerlo de conformidad con la ley de protección de datos, por lo general que tengan que entrar en las cláusulas contractuales tipo aprobadas por la Unión Europea como proporcionar una protección equivalente a lo que sería en su lugar tenía el datos personales se mantuvieron en el Espacio Económico Europeo.
    Podemos proporcionar más información sobre los países no pertenecientes al EEE a la que transferir su personal
    Los datos a petición.
    <br/><br/>12 ¿Cuáles son mis derechos en relación con Linetor procesamiento de mis datos personales y cómo puedo ejercer?<br/><br/>
    La ley le otorga ciertos derechos respecto de la información que tenemos sobre usted. A continuación se muestra una breve descripción de los mismos.
    <br/><br/>12.1 Acceso.<br/><br/>
    Usted tiene derecho a recibir una copia de los datos personales que tenemos de usted. Podemos hacer un cargo razonable por las copias adicionales de que los datos más allá de la primera copia, basado en nuestros costos administrativos. Cuando los datos son datos que ha dado a nosotros, usted tiene el derecho a recibir una copia de la misma en un formato electrónico común, y proporcionar copias de la misma a otras personas si lo desea.
    <br/><br/>12,2 Corrección.<br/><br/>
    Usted tiene el derecho a que los datos personales que obran en nuestro poder corregirse si es materialmente inexactos. Tenga en cuenta que esto no se extiende a cuestiones de opinión, como la retroalimentación recibida en usted como propietario o inquilino, o de decisiones en cuanto a su idoneidad.
    <br/><br/>12,3 Supresión.<br/><br/>
    En algunas circunstancias limitadas, usted tiene el derecho a que los datos personales que obran en nuestro poder borrar (el derecho a ser olvidado”). Este derecho no está generalmente disponible donde todavía tenemos una razón legal válida para mantener los datos.
    <br/><br/>12,4 objeción.<br/><br/>
    Usted tiene el derecho a oponerse a nuestro tratamiento de sus datos personales en los que se basan en “intereses legítimos” como nuestra base legal para su procesamiento, pero que puede ser capaz de continuar con el procesamiento si nuestro interés es mayor que su objeción.
    <br/><br/>12.5 La opción de comercialización.<br/><br/>
    Usted tiene el derecho de requerir que deje de usar sus datos personales para enviarle información de marketing. Si quieres que dejemos que el envío de información de marketing, la forma más rápida y más eficiente es el uso de los enlaces proporcionados “darse de baja” en nuestras comunicaciones (aunque se puede dirigir en contacto con nosotros si lo prefiere).
    <br/><br/>12.6 Restricción Temporal.<br/><br/>
    También tiene el derecho en algunas circunstancias para solicitar que las restricciones temporales se colocan en forma de procesar sus datos personales, por ejemplo, si usted disputa su exactitud o donde estamos procesando en la base de nuestro interés legítimo y usted refuta nuestra evaluación que nuestro interés anula sus derechos.
    <br/><br/>12,7 Retirar Consentimiento<br/><br/>
    Si estamos procesando sus datos personales sobre la base de su consentimiento, usted tiene el derecho de retirar el consentimiento en cualquier momento, en cuyo caso nos detendremos que el procesamiento a menos que tengamos otra base jurídica sobre la cual continuar.
    <br/><br/>13 ¿Cómo y cuándo se Linetor hacer cambios a esta guía?<br/><br/>
    Podemos actualizar esta guía de privacidad de vez en cuando al actualizar esta página web.
    Cuando actualizamos esta guía para incluir completamente nuevos datos personales, o el uso de datos de carácter personal existentes en una forma totalmente nueva, se le notificará que los cambios han sido realizados utilizando la dirección de correo electrónico que tenemos para ti, si los hubiere.
    Esta guía se actualizó por última vez el 15 de junio de 2023.


        </p>
    </div>
</template>
<script>
export default {
   
    data() {
        return {

        }
    }
}
</script>