<style>
.descripcion_container{
       position: relative;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;
    color: black;
    margin-bottom: 100px;
    text-align: left;
}
.descripcion_container h1{
    color: #007bff;
}
.descripcion_container strong{
    color: black;
}
.descripcion_container ul{
    color: black;
}

</style>
<template>

<div class="descripcion_container">
    <h1>Términos de servicio
    </h1>
    <br>
    <p>
    Linetor ofrece un mercado en línea para reservar alojamiento para estudiantes.Proporciona servicios de reserva tanto a usuarios (que buscan alojamiento) como aproveedores de alojamiento (que desean ofrecer su alojamiento a los usuarios). Noalquila alojamiento directamente a los usuarios. <br/><br/>

    Según las leyes, si eres consumidor tienes ciertos derechos de cancelación de tu acuerdo con Linetor para recibir sus servicios de alojamiento dentro de un plazo de anulación de compra de 14 días según tu aceptación de estos términos de servicio (ver cláusula 7 para más información sobre estos derechos).<br/><br/>

    Sin embargo, al utilizar nuestros servicios para reservar (si eres usuario) alojamiento o aceptar reservas (si eres proveedor de alojamiento) de alojamiento durante tal periodo de anulación de compra estás solicitando nuestros servicios y, por tanto, dejarás de tener derecho a cancelar, según la ley del consumidor, desde el momento en que se realice la reserva (ver cláusula 7 para información detallada). Aún mantendrás los derechos contractuales de cancelar, establecidos en la cláusula 11.<br/><br/>

    Además, si está utilizando nuestros servicios de reserva de alojamiento, es probable que se le requiera pagar un Primer Pago de la Renta (como se define más adelante) a su proveedor de alojamiento a través de Linetor (quien lo recibirá en nombre del proveedor y se lo enviará a ellos). Este Primer Pago de Alquiler se paga a su proveedor de alojamiento y, por tanto, sólo es reembolsable de acuerdo con sus derechos contractuales de cancelación, que se establecen en la cláusula 11<br/><br/>
    
    </p>
    <strong>Por favor, lee este documento detenidamente. Contiene información importante sobre tus derechos y obligaciones. En concreto, contiene la siguiente información:</strong><br><br>

    <ul>
        <li>Exclusión y limitación de responsabilidad (en la cláusula 21), que de forma conjunta limita nuestras responsabilidades para con los usuarios y los proveedores de alojamiento;</li>
        <li>Una cláusula de devolución según la cual los usuarios (cláusula 12.10) aceptan reembolsar a Linetor en determinadas circunstancias;</li>
        <li>Una cláusula de exención de responsabilidad (cláusula 22) según la cual los proveedores de alojamiento, como empresas, deberán eximir de responsabilidad a Linetor en determinadas circunstancias.
    </li>
    </ul>
    <hr>

    <p>
    Te aconsejamos que revises detenidamente estas cláusulas antes de proceder. 
    <br><br>
    Si hay una incoherencia entre cualquiera de las disposiciones de esta versión en Inglés y una versión traducida, prevalecerá lo dispuesto en la presente versión en idioma Inglés. Las versiones traducidas de estas Condiciones del servicio y las condiciones de uso se proporcionan únicamente para su comodidad.
    </p>

    <p>1. Sobre estos Términos de Servicio</p>
   
    <p>En estos términos de servicio:</p>
    <ul>
    <li>«Primer pago del alquiler» se refiere al importe del primer pago del alquiler que debe abonar el usuario a Linetor (en nombre del proveedor de alojamiento), tal y como se indica en la página web con cierta frecuencia;</li><br/>
    <li>«Alojamiento» se refiere a una habitación, un estudio, casa o piso que ofrece un proveedor a través de la página web;</li><br/>
    <li>«Proveedor de alojamiento» se refiere al proveedor de alojamiento que aparece en la lista de la página web;</li><br/>
    <li>“Período bloqueado” significa el período de tiempo especificado en el email del Programa de Selección durante el cual solamente un usuario cuya solicitud de reserva ha sido aceptada por Linetor como parte del Programa de Selección puede ocupar el alojamiento;</li><br/>
    <li>«Reserva aceptada» se refiere al email enviado por Linetor al usuario y al proveedor de alojamiento tan pronto como la solicitud de reserva haya sido aceptada por el proveedor de alojamiento correspondiente;</li><br/>
    <li>«Solicitud de reserva» se refiere a una solicitud para reservar alojamiento realizada por el usuario;</li><br/>
    <li>"Reserva Confirmada" se refiere a una solicitud para reservar alojamiento que fue aceptada por el proveedor de alojamiento y pagada por el Usuario.</li><br/>
    <li>«Valor de la reserva» se refiere al importe correspondiente a la suma de todos los pagos del alquiler (incluyendo el primer pago y pagos futuros, pero excluyendo el cargo por servicio (pago único), el depósito y la fianza de reserva (si procede));</li><br/>
    <li>«Día laboral» se refiere a cualquier día de la semana excepto sábados, domingos y festivos en el Reino Unido;</li><br/>
    <li>«Fecha de inicio del contrato» se refiere a la fecha de inicio del periodo reservado, tal y como se especifica en la confirmación de reserva correpondiente;</li><br/>
    <li>«Alojamiento verificado al 100 %» se refiere al alojamiento en el que tanto el propio alojamiento como el proveedor han completado todos los pasos del proceso de verificación establecidos en la página de Linetor.</li><br/>
    <li>«Pagos futuros» se refiere a todos los pagos que el usuario debe realizar en el futuro directamente al proveedor de alojamiento (además del primer pago del alquiler), que incluyen cualquier pago del alquiler a realizar por el usuario al proveedor y el depósito;</li><br/>
    <li>«Fianza de reserva» se refiere, donde sea pertinente, al importe (tal y como se especifica en la lista de alojamiento correspondiente) que el usuario paga en el momento de reservar con el propósito de conservar la reserva del alojamiento. Dicho importe lo abona Linetor de forma inmediata al proveedor de alojamiento y, a modo aclaratorio, no pretende cubrir ningún desperfecto que se produzca durante la estancia del usuario, así como cualquier otra incumplimiento del contrato de alquiler por el usuario.</li><br/>
    <li>"Cargo por servicio (pago único)" se refiere al importe (IVA incluido) que el usuario paga a Linetor en concepto de cargos cuando se acepta una reserva, teniendo en cuanto los servicios proporcionados por Linetor al usuario. Se calcula como porcentaje del primer pago del alquiler (tal y como se comunica con frecuencia). Este cargo se aplica al alojamiento concreto mostrado en la página web. Por lo tanto, si dicho alojamiento es una habitación individual en un piso o casa de más habitaciones, este cargo se aplica a la habitación concreta reservada y no al piso o la casa en su totalidad.</li><br/>
    <li>«Servicios» se refiere a la prestación de reservas de alojamiento de usuarios con proveedores por medio de la página web;</li><br/>
    <li>«Contrato de alquiler» se refiere al contrato de alquiler o uso de vivienda (ya sea formal o no, por escrito o no) entre el proveedor de alojamiento y el usuario en lo que afecta al alquiler del alojamiento;</li><br/>
    <li>«Periodo» se refiere al plazo de menor duración de: (i) el periodo del alquiler, basado en la fecha de inicio y finalización de contrato (tal y como se especifica en la confirmación de reserva correspondiente); o (ii) periodo de 12 meses a contar desde la fecha de inicio del contrato;</li><br/>
    <li>“Comisión de Linetor" es el cargo (IVA incluido) que Linetor aplica al proveedor de alojamiento cuando se realiza una reserva, teniendo en cuenta los servicios proporcionados por Linetor al proveedor a través de la página web. Se calcula como porcentaje del valor de la reserva (tal y como informamos con frecuencia) además del IVA correspondiente cobrado en ese porcentaje del valor de la reserva. Después es deducida del importe que Linetor transfiere al proveedor del alojamiento.</li><br/>
    <li>«Linetor» y «nosotros» hacen referencia a Linetor Limited;</li><br/>
    <li>«Usuario» se refiere a aquel que utiliza la página web para buscar alojamiento;</li><br/>
    <li>«Página web» se refiere a cualquier página gestionada por Linetor, incluidas, entre otras, la páginas <a href="http://linetor.net" target="_blank" rel="noopener noreferrer"></a> y sus subdominios, cualquier perfil de red social asociado y cualquier aplicación para dispositivos móviles que Linetor pueda lanzar ocasionalmente</li><br/>
    <li>«Tú» se refiere al usuario o proveedor de alojamiento (según el caso).</li><br/>
    </ul>
    <p>Estos términos de servicio establecen los términos en los cuales ofrecemos los servicios y la página web y según los cuales aceptas utilizar los servicios proporcionados por nosotros y la página web gestionada por nosotros.</p><br/>
    <p>Por favor, lee estos términos de servicio detenidamente y asegúrate de comprenderlos antes de utilizar los servicio o la página web. Si no aceptas estos términos de servicio debes dejar de utilizar los servicios y la página web de forma inmediata.</p><br/>

    <p>2. Aceptación de los Términos de Servicio</p>
    <ol>
    <li>Al registrarte para utilizar los servicios y/o la página web y/o al pedir a Linetor que cree listas de alojamiento para ti aceptas estar vinculado por estos términos de servicio.</li>
    <li>En cualquier momento podemos modificar estos términos de servicio. Te notificaremos de cualquier cambio en estos términos de servicio por email (a la dirección que hayas proporcionado en cualquier formulario de la página) antes de que los cambios se hagan efectivos para darte la oportunidad de cancelar el contrato si no estás de acuerdo con ellos. Si continúas utilizando los servicios y/o la página web después de la fecha de caducidad del derecho de cancelación, aceptas estar vinculado por dichos cambios. Puedes revisar la versión más reciente de nuestros términos de servicio en cualquier momento haciendo clic en el enlace Términos y Políticas situado en la parte inferior de la página.</li>
    </ol>
    <p>3. ¿Qué es la página web?</p>
    <ol>
    <li>La página web es una plataforma electrónica que permite a los usuarios ojear, buscar y reservar alojamiento en los destinos y a los proveedores de alojamiento anunciar alojamiento a los usuarios. Las reservas pueden ser para estancias cortas o largas, con una duración mínima de 28 días.</li>
    <li>Linetor ofrece servicios de reserva a usuarios y proveedores de alojamiento, pero no ofrece alojamiento a usuarios en nombre de los proveedores. Los contratos de alquiler se realizarán entre usuario y proveedor de alojamiento. Linetor no será una de las partes en tales contratos de alquiler y no tendrá ningún derecho u obligación que surja del mismo.</li>
    </ol>
    <p>4. Información Mostrada en la Página Web</p>
    <ol>
    <li>Linetor hace todo lo posible por garantizar que toda la información publicada en la página web sea clara, precisa, completa y no dé lugar a ambigüedades. Asimismo, intentamos garantizar que solo los alojamientos que cumplen ciertos niveles de limpieza, decoración, seguridad y apariencia del edificio, instalaciones y servicio al cliente se anuncian en la página web. Sin embargo, no lo podemos garantizar, ya que nosotros no proporcionamos el alojamiento.</li>
    <li>Linetor depende de los proveedores de alojamiento para ofrecer información apropiada y precisa y para mantener actualizada dicha información. No verificamos la veracidad ni el estado de actualización de la información publicada o proporcionada por los proveedores, a menos que confirmemos de forma expresa haberlo hecho. Si eres un proveedor de alojamiento reconoces y aceptas que eres el único responsable del contenido de la información publicada o enviada a nosotros relativa a cualquier alojamiento anunciado en la página web.</li>
    <li>Si eres proveedor de alojamiento debes comprobar la información de la lista correspondiente de todos los alojamientos que ofrezcas en nuestra página, incluyendo (entre otra información) el precio, la disponibilidad y las instalaciones antes de aceptar solicitudes de reserva a través de Linetor.</li>
    <li>Como usuario, reconoces y aceptas que Linetor no tiene ninguna responsabilidad en relación a la información publicada en la página web o que nos haya sido compartida por los propietarios en relación al Alojamiento. Asimismo, también reconoces que cualquier pérdida o daño que puedas sufrir y que resulte de la información escrita en relación al Alojamiento es responsabilidad del propietario. En el caso de que un Alojamiento haya sido verificado por Linetor, Linetor solo será responsable de la precisión de la información que se ha comprobado. Sin embargo, Linetor no será responsable de ningún cambio en el alojamiento después de la verificación.</li>
    <li>Nos reservamos el derecho a eliminar sin aviso previo (o concluir la colaboración) la totalidad o una parte de cualquier alojamiento publicado en la página web del que recibamos una queja por estar obsoleto, no ser correcto o violar los derechos de un tercero. Haremos lo posible por procesar y tomar medidas sobre dichas quejas de forma rápida y razonable.</li>
    <li>Aconsejamos a los usuarios leer todos los documentos con detenimiento y asegurarse de comprender el coste del alojamiento (ej. alquiler y otros gastos), los términos y la duración del Contrato de Alquiler y cualquier restricción de uso del alojamiento que puedan ser comunicadas. Las fotografías y videos del alojamiento en la plataforma deberán ser usadas por los usuarios como una guía de lo que parece el Alojamiento, pero nunca como una representación exacta.</li>
    </ol>

    <p>5. Reservar Alojamiento</p>
    <ol>
    <li>Los usuarios podrán enviar una solicitud de reserva de alojamiento a través de la página. El usuario debe elegir las fechas y el alojamiento que desee reservar y hacer clic en el botón de «Solicitud de reserva».</li>
    <li>El Usuario garantiza que es mayor de edad para formalizar un contrato, como un contrato de alquiler, en el país donde se ubica el alojamiento. Asimismo, reconoce que, si el usuario incumple dicha garantía, Linetor tendrá derecho a retener cualquier cargo por servicio que haya recibido del usuario como compensación por el incumplimiento de la garantía por parte del usuario. Es responsabilidad del usuario comprobar que puede formalizar un contrato de forma legal con el proveedor de alojamiento.</li>
    <li>El Usuario deberá proporcionar los datos de pago para el primer pago del alquiler, la tasa de servicio (pago único) y el depósito de reserva (si hay) en el momento de la solicitud de reserva. Después de que la solicitud de reserva sea aceptada por el Proveedor de Alojamiento, el pago será cobrado. Si el pago se produce, el Usuario recibirá un email confirmando que la reserva ha sido pagada y confirmada.</li>
    <li>Una vez que la Solicitud de Reserva haya sido pagada, el sistema crea una autorización para el primer pago del alquiler, el cargo por servicio (pago único) y la fianza de reserva (si hay), seguido de la retirada de los fondos. La tarjeta del Usuario será cargada, en concordancia con estos Términos y Condiciones, dando lugar a una Reserva Confirmada.</li>
    <li>Después del pago del Usuario, la reserva es confirmada y Linetor presentará al usuario al proveedor de alojamiento elegido.</li>
    </ol>
    <p>6. Confirmación de Reserva</p>
    <ol>
    <li>El Proveedor de Alojamiento puede aceptar o rechazar la solicitud de reserva. Si se rechaza la solicitud de reserva, Linetor notificará rápidamente al Usuario y hará un esfuerzo por recomendar otras propiedades similares. Si el Proveedor de Alojamiento acepta la solicitud de reserva, se cobrará el pago respectivo al primer mes de alquiler, la tasa de servicio (pago único) y el depósito de seguridad (si se requiere). Después de que el pago esté completado Linetor informará a ambas partes de los pasos a seguir.</li>
    <li>Una vez que se confirme la reserva el usuario será el responsable de las siguientes cosas:</li>
    <ul>
    <li>(a) cumplir cualquier otro requisito que el proveedor de alojamiento establezca. Estos requisitos pueden incluir (entre otros) proporcionar referencias personales y/o información de un avalista. El proveedor de alojamiento también puede requerir dicha información antes de aceptar una solicitud de reserva, en cuyo caso se informará al usuario.</li>
    <li>(b) permanecer en el alojamiento correspondiente durante el periodo completo, cumplir el contrato de alquiler y realizar todos los pagos en su totalidad a Linetor y al proveedor de alojamiento de forma inmediata según sea requerido por estos términos de servicio o el contrato de alquiler, según el caso.</li>
    </ul>
    <li>El proveedor de alojamiento acuerda que una vez que acepte una solicitud de reserva y el Usuario paga, la reserva está confirmada, y el proveedor de alojamiento estará obligado a ofrecer el alojamiento correspondiente durante las fechas y al precio mostrado en la solicitud y, además, aceptará que los datos de la propiedad, las condiciones y las instalaciones del alojamiento no serán demasiado diferentes a los establecidos en la lista de Linetor. Si no lo hace podrá dar lugar a quejas y cancelaciones por parte del usuario (que puede tener derecho a un reembolso total).</li>
    </ol>
    <p>7. Reglamento de Contratos de Consumo (Información, Cancelación y Cargos Adicionales) de 2013</p>
    <ol>
    <li>Sujeto a las cláusulas 7.3 y 7.4. si eres consumidor tienes derecho a cancelar en cualquier momento el acuerdo con nosotros para ofrecer servicios en un periodod de 14 días desde la primera aceptación de estos términos de servicio, de conformidad con la cláusula 2.</li>
    <li>Para cancelar el acuerdo con nosotros para ofrecer los servicios simplemente debes avisar a Linetor de que deseas cancelarlo. Puedes usar una copia del formulario ubicado en el reverso de estos Términos de servicio como cláusula para hacerlo. Si usas este método, Linetor te enviará un email para confirmar la recepción de tu cancelación.</li>
    <li>Si realizas o aceptas una solicitud de reserva durante el periodo de cancelación de 14 días arriba establecido, deberás pedir a Linetor que ofrezca sus servicios durante esos 14 días y, en consecuencia, perderás tu derecho legal de cancelar el acuerdo con Linetor para ofrecer los servicios una vez que se acepte la solicitud de reserva de acuerdo con la cláusula 6. En ese punto el cargo por servicio (pago único) (si hay) deberá abonarse de acuerdo con estos términos de servicio. Tus derechos contractuales de cancelación , tal y como se establecen en la cláusula 11, seguirán aplicándose.</li>
    <li>En todo caso, la cancelación del acuerdo con Linetor para ofrecer los servicios no tendrá efecto en ningún contrato de alquiler que se haya formalizado o vaya a formalizarse.</li>
    <li>Todos los pagos realizados por el usuario a Linetor en nombre del proveedor de alojamiento según, o en relación a, el contrato de alquiler (primer pago del alquiler incluido) se mantendrán en fideicomiso por Linetor para el proveedor de alojamiento y solo se devolverán al usuario según lo establecido en la cláusula 11.</li>
    </ol>
    <p>8. Primer Pago del Alquiler</p>
    <ol>
    <li>El primer pago del alquiler es un cargo de reserva contractual a pagar por el usuario a Linetor, quien lo recibirá en nombre del proveedor de alojamiento y lo conservará en fideicomiso para el proveedor.</li>
    <li>A menos que se acuerde por escrito lo contrario, Linetor pagará al proveedor de alojamiento en un plazo de dos días laborales a la fecha de entrada en el alojamiento, sujeto a deducciones por la comisión de Linetor, el primer pago del alquiler y la fianza de reserva (si hay).</li>
    <li>Linetor no es miembro de ningún plan de protección de fianzas en relación al primer pago del alquiler y no conservará ninguna fianza por desperfectos o comportamiento para el proveedor, que puede solicitarlo al usuario .</li>
    </ol>
    <p>9. Contrato de Alquiler</p>
    <ol>
    <li>El contrato de alquiler entrará en vigor solo cuando el proveedor de alojamiento y el usuario acuerden los términos para la ocupación del alojamiento. El contrato de alquiler se formalizará entre un usuario y un proveedor de alojamiento y, para evitar cualquier duda, Linetor no formará parte de dicho contrato.</li>
    <li>Linetor no será responsable de ningún incumplimiento o fallo en el cumplimiento del contrato de alquiler por parte del proveedor de alojamiento y/o por defectos en cualquier alojamiento ofrecido por el proveedor.</li>
    <li>Linetor no será responsable de ningún incumplimiento o fallo en el cumplimiento de cualquier contrato por parte del usuario incluyendo, entre otros, cambios en las fechas de entrada o salida. El proveedor de alojamiento cuenta con mecanismos para hacer cumplir al usuario los datos previamente acordados (incluidas las fechas de entrada y salida). Por lo tanto, nunca habrá pagos o devoluciones a realizar al proveedor por parte de Linetor.</li>
    </ol>

    <p>10. Fecha de Entrada</p>
    <p>El usuario llegará al alojamiento el día establecido en la confirmación de reserva correspondiente. Todos los futuros pagos se realizarán directamente al proveedor de alojamiento. La lista de alojamiento establece qué constituye los futuros pagos. Para cualquier aclaración el usuario deberá contactar con el proveedor de alojamiento.</p>

    <p>11. Cancelaciones</p>
    <ol>
    <li>Además de y sujeto a los derechos de cancelación del usuario según la cláusula 7, el usuario podrá asimismo cancelar la reserva de acuerdo con la política de cancelación elegida por el proveedor de alojamiento y según lo establecido más abajo.</li>
    <li>El proveedor de alojamiento podrá elegir UNA de las siguientes políticas de cancelación. Una vez que haya hecho su elección y notificado a Linetor, la política elegida estará en vigor para todas las futuras reservas. Si no elige ninguna, la «política de cancelación moderada» se aplicará por defecto. El proveedor de alojamiento le otorga a Linetor autoridad irrevocable para vincular al proveedor de alojamiento a la política de cancelación seleccionada (o por defecto), procesar (y reembolsar, si fuera necesario) todos los primeros pagos del alquiler y aplicar la comisión de Linetor, del modo abajo establecido.</li>
    <li>Se permitirá al usuario cancelar en las siguientes circunstancias y según los siguientes términos:</li>
    <ul>
    <li>(a) Después de que se haya realizado una reserva y formalizado un contrato de alquiler según lo dispuesto en la cláusula 9.1 y/o el usuario se haya mudado al alojamiento, no se podrá cancelar, excepto según las disposiciones sobre finalización incluidas en dicho contrato de alquiler.</li>
    <li>(b) Política de cancelación flexible: antes de la formalización del contrato de alquiler entre el usuario y el proveedor de alojamiento, pero después de que el proveedor de alojamiento haya aceptado la solicitud de reserva y el usuario haya realizado el primer pago del alquiler, si el usuario informa a Linetor de que desea cancelar:</li>
    <ul>
    <li>(i) con una antelación de menos de 15 días a la fecha de inicio de contrato, no se harán reembolsos del primer pago del alquiler (y se cancelará la reserva);</li>
    <li>(ii) con una antelación de entre 15 y 30 días a la fecha de inicio del contrato, Linetor reembolsará el 50 % del primer pago del alquiler (y se cancelará la reserva);</li>
    <li>(iii) con más de 30 días de antelación a la fecha de inicio del contrato, Linetor reembolsará el 100 % del primer pago del alquiler (y se cancelará la reserva).</li>
    </ul>
    <p>En relación a la cláusula 11.3(b)(i), en caso de cancelación, el proveedor de alojamiento deberá recibir el importe del primer pago del alquiler después de la deducción de la comisión de Linetor. En relación a la cláusula 11.3(b)(ii), en caso de cancelación, el proveedor de alojamiento deberá recibir el 50 % del primer pago del alquiler después de la deducción de la comisión de Linetor.</p>
    <li>(c) Política de cancelación moderada: antes de la formalización del contrato de alquiler entre el usuario y el proveedor de alojamiento, pero después de que el proveedor de alojamiento haya aceptado la solicitud de reserva y el usuario haya realizado el primer pago del alquiler, si el usuario informa a Linetor de que desea cancelar:</li>
    <ul>
    <li>(i) con una antelación de menos de 30 días a la fecha de inicio del contrato, no se reembolsará el primer pago del alquiler (y se cancelará la reserva);</li>
    <li>(ii) con una antelación de entre 30 y 60 días a la fecha de inicio del contrato, Linetor reembolsará el 50 % del primer pago del alquiler (y se cancelará la reserva);</li>
    <li>(iii) con una antelación de más de 60 días a la fecha de inicio del contrato, Linetor reembolsará el 100 % del primer pago del alquiler (y se cancelará la reserva).</li>
    </ul>
    <p>En relación a la cláusula 11.3(c)(i), en caso de cancelación, el proveedor de alojamiento deberá recibir el importe del primer pago del alquiler después de la deducción de la comisión de Linetor. En relación a la cláusula 11.3(c)(ii), en caso de cancelación, el proveedor de alojamiento deberá recibir el 50 % del primer pago del alquiler después de la deducción de la comisión de Linetor.</p>
    <li>(d) Política de cancelación estricta: antes de la formalización del contrato de alquiler entre el usuario y el proveedor de alojamiento, pero después de que el proveedor de alojamiento haya aceptado la solicitud de reserva y el usuario haya realizado el primer pago del alquiler, si el usuario informa a Linetor de que desea cancelar:</li>
    <ul>
    <li>(i) con una antelación de menos de 30 días a la fecha de inicio del contrato, no se reembolsará el primer pago del alquiler (y se cancelará la reserva);</li>
    <li>(ii) con una antelación de entre 30 y 90 días a la fecha de inicio del contrato, Linetor reembolsará el 50 % del primer pago del alquiler (y se cancelará la reserva);</li>
    <li>(iii) con una antelación de más de 90 días a la fecha de inicio del contrato, Linetor reembolsará el 100 % del primer pago del alquiler (y se cancelará la reserva).</li>
    </ul>
    <p>En relación a la cláusula 11.3(d)(i), en caso de cancelación, el proveedor de alojamiento deberá recibir el importe del primer pago del alquiler después de la deducción de la comisión de Linetor. En relación a la cláusula 11.3(d)(ii), en caso de cancelación, el proveedor de alojamiento deberá recibir el 50 % del primer pago del alquiler después de la deducción de la comisión de Linetor.</p>
    <li>(e) Política de Cancelación: no reembolsable: antes de que el contrato de arrendamiento ha sido firmado por el usuario y el proveedor de alojamiento pero después del hospedero ha aceptado la solicitud de reserva y después de que el usuario haya hecho la primera pago de alquiler si el usuario Uniplaces notifica que desea cancelar el reembolso no será debido en relación con el pago de alquiler primera (y la reserva será cancelada).</li>
    <p>En relación a la cláusula 11.3(e), en caso de cancelación, el proveedor de alojamiento deberá recibir la parte del primer pago del alquiler correspondiente tras la deducción de la comisión de Linetor.</p>
    </ul>
    <li>Para evitar cualquier duda, si el usuario cancela una reserva según esta cláusula 11, Linetor no reembolsará el cargo por servicio (pago único).</li>
    </ol>
    <p>12. Garantía Linetor</p>
    <p>Linetor confía en que los usuarios que utilicen sus servicios permanezcan en su alojamiento todo el periodo reservado. Esta confianza surge del hecho de que Linetor lleva a cabo diversas comprobaciones del perfil de los usuarios que incluyen, entre otras cosas:</p>
    <ul>
    <li>(a) obtener el nombre completo, número de teléfono, país de residencia y nacionalidad del usuario. Sin embargo, Linetor no realiza pruebas de conducta sobre la situación de inmigración de los usuarios como parte de las comprobaciones. Se aconseja a los proveedores de alojamiento que lleven a cabo las comprobaciones sobre inmigración a los usuarios que sean necesarias en el país donde se ubique el alojamiento.</li>
    <li>(b) obtener información adicional sobre el usuario como, por ejemplo, si el usuario es estudiante de grado, posgrado o curso de idiomas y, opcionalmente, en qué universidad o centro educativo va a estudiar;</li>
    </ul>
    <p>12.2 Como resultado de la confianza que surge de las comprobaciones y la confianza en nuestra capacidad de volver a alquilar un alojamiento disponible con rapidez, estamos dispuestos a ofrecer una garantía de segunda reserva a ciertos proveedores de alojamiento. Para que un proveedor reciba dicha garantía Linetor deberá aceptar de forma específica ofrecer dicho servicio al proveedor, lo que comunicará al proveedor por email. Si Linetor no lo hace, esta cláusula 12 no se aplicará. La garantía de segunda reserva entra en vigor desde la fecha de inicio de reserva del usuario (según lo establecido por Linetor), termina un mes antes del final del periodo correspondiente y funciona de la siguiente forma:</p>
    <ul>
    <li>(a) tras la fecha de entrada a un alojamiento, si un usuario abandona por cualquier motivo el alojamiento reservado a través de Linetor, entonces el proveedor de alojamiento, sujeto a estos términos de servicio, autoriza a Linetor a volver a alquilar de forma inmediata el alojamiento (para un período igual o superior al que falta hasta terminar el contrato ;</li>
    <li>(b) siempre y cuando el proveedor de alojamiento haya cumplido con el resto de esta cláusula 12 y hasta que Linetor vuelva a alquilar el alojamiento o finalice el periodo del contrato (lo que ocurra antes), Linetor deberá pagar al proveedor de alojamiento (como única compensación al proveedor debido a que Linetor no haya podido volver a alquilar el alojamiento) una cantidad equivalente al alquiler del número de días exactos restantes hasta la fecha de finalización del contrato, tras agotar cualquier crédito retenido por el proveedor en relación al primer pago de alquiler, alquiler por adelantado o depósito que en ocasiones quede a deber al proveedor según el contrato de alquiler;</li>
    <li>(c) Linetor tendrá criterio propio para volver a alquilar el alojamiento a la persona y con el descuento en el alquiler actual como estime razonable y apropiado para conseguir volver alquilar el alojamiento de forma rápida durante el periodo restante hasta el final del contrato. Sin embargo, si Linetor vuelve a alquilar el alojamiento con un descuento del alquiler mensual de más del cinco por ciento del alquiler pagado por el usuario anterior, Linetor deberá seguir pagando solo la cantidad en exceso (según la claúsula 12.3) durante el periodo restante hasta el final del contrato.</li>
    </ul>
    <p>12.3 El primer pago según la cláusula 12.2(b) o (c) deberá realizarse el primer día laboral del mes posterior a la finalización del periodo de 60 días que comienza el día que Linetor recibe una reclamación probada del pago por parte del proveedor de alojamiento. Después de eso Linetor deberá realizar dicho pago de forma mensual el primer día laboral del mes.</p>
    <p>12.4 Linetor no tendrá obligación ninguna de reembolsar el dinero del alquiler según la cláusula 12.2(b) o (c) hasta el punto en que dicho alquiler sobrepase el valor del mercado de una propiedad del tamaño y la calidad del alojamiento en la ubicación en que se encuentra, según el caso establecido de forma razonable por Linetor.</p>
    <p>12.5 Si el proveedor de alojamiento:</p>
    <ul>
    <li>(a) vuelve a alquilar el alojamiento sin utilizar Linetor;</li>
    <li>(b) de otro modo impide o deja de facilitar volver a alquilar el alojamiento por parte de Linetor (incluido mantener el alojamiento en las condiciones establecidas en las cláusulas 12.6(h) (v) a (vii) de abajo);</li>
    <li>(c) recibe el pago del alquiler del avalista del usuario según el contrato de alquiler; o</li>
    <li>(d) no notifica a Linetor cuando se vuelva a alquilar el alojamiento, no se pagará ninguna cantidad al proveedor del alojamiento según la cláusula 12.2(b) o (c).</li>
    </ul>
    <p>12.6 Además, no se pagará ninguna cantidad según la cláusula 12.2(b) o (c):</p>
    <ul>
    <li>(a) en relación con cualquier primer pago del alquiler, alquiler por adelantado y/o depósito que el proveedor de alojamiento haya recibido o ningún pago inicial de alquiler que el proveedor de alojamiento no haya recibido al comienzo del contrato de alquiler;</li>
    <li>(b) como resultado de un acuerdo entre el proveedor de alojamiento y el usuario para que el usuario pague menos alquiler o para finalizar o suspender el pago del alquiler tal y como se establece en la lista de alojamiento;</li>
    <li>(c) como resultado de la marcha del usuario a otro alojamiento del proveedor (o de cualquier persona o entidad directa o indirectamente relacionada con el proveedor) durante el periodo del contrato;</li>
    <li>(d) a menos que el proveedor de alojamiento informe a Linetor por escrito en nuestro formulario help.linetor.net de que el usuario ha abandonado el alojamiento y ha dejado de pagar alquiler en un plazo de 14 días desde que esto ocurra;</li>
    <li>(e) a menos que el proveedor de alojamiento primero haya solicitado por escrito (que puede incluir, entre otros medios, el email) el pago del alquiler correspondiente por parte del usuario y dicho pago siga pendiente durante más de siete días desde la fecha en que el usuario haya recibido dicha solicitud;</li>
    <li>(f) en relación con:</li>
    <ul>
    <li>(i) primas de seguros, gastos o facturas de servicios públicos que deba pagar el proveedor de alojamiento;</li>
    <li>(ii) cualquier cantidad que el proveedor de alojamiento esté obligado a pagar como alquiler o cargo por servicio a otro proveedor de alojamiento o empresa de gestión;</li>
    <li>(iii) letra de hipoteca, intereses, cargos o penalizaciones que deba pagar el proveedor de alojamiento a los patrocinadores del proveedor;</li>
    <li>(iv) cualquier alquiler acordado en un contrato que exceda el alquiler establecido en la lista de alojamiento;</li>
    </ul>
    <li>(g) en relación a cargos de servicio, gastos, cargos, facturas de servicios públicos y multas pendientes que debe pagar el usuario según el contrato de alquiler o el coste de cualquier deterioro o reparación en el alojamiento exigido por el proveedor de alojamiento al finalizar o concluir cualquier contrato de alquiler;</li>
    <li>(h) si el usuario abandona el alojamiento, cancela o da por finalizada una reserva o un contrato de alquiler como resultado de las siguientes situaciones:</li>
    <ul>
    <li>(i) el proveedor de alojamiento infringe estos términos de servicio o el contrato de alquiler;</li>
    <li>(ii) el proveedor de alojamiento ofrece información ambigua o incorrecta sobre el alojamiento o muestra el alojamientode forma inadecuada al usuario (ya sea a través de www.linetor.net o no) antes del inicio del contrato de alquiler;</li>
    <li>(iii) el usuario cancela la reserva de acuerdo con la política de cancelación establecida en estos términos de servicio;</li>
    <li>(iv) el proveedor ha aceptado la reserva por error o equivocación (y no ha comunicado dicho error o equivocación por escrito usando nuestro formulario help.linetor.net);</li>
    <li>(v) el alojamiento no está disponible para ser ocupado o no es accesible para el usuario;</li>
    <li>(vi) el alojamiento está:</li>
    <ul>
        <li>(i) en unas condiciones poco seguras, es inhabitable; o</li>
    <li>(ii) se encuentra en tal estado de deterioro que impide al usuario hacer uso de él; o</li>
    <li>(iii) debido a cualquier circunstancia fuera del control de cualquiera de las partes, incluyendo pero sin limitarse a:</li>
    <ul>
    <li>casos de fuerza mayor, inundaciones, sequía, terremoto o desastre natural;</li>
    <li>epidemia o pandemia;</li>
    <li>ataque terrorista, guerra civil, conmoción civil o revueltas, guerra, amenaza de guerra o preparación para un conflicto armado;</li>
    <li>desplome de edificios, fuego, explosión o accidente; y</li>
    <li>interrupción o fallo del servicio público.</li>
    </ul>
    
    </ul>
    <li>(vii) el proveedor de alojamiento no arregla los desperfectos del alojamiento o problemas de mantenimiento que surgen durante el periodo de validez del contrato, de forma que impide el disfrute del alojamiento por parte del usuario;</li>
    <li>(viii) el proveedor de alojamiento anima, persuade, obliga o instiga al usuario a hacerlo;</li>
    <li>(ix) cualquier acción conjunta, arreglo o acuerdo con el proveedor que se haya realizado o formalizado con la intención de permitir al proveedor de alojamiento beneficiarse injustamente de las disposiciones de pago de la cláusula 12.2(b) y (c);</li>
    <li>(x) o si el usuario abandona el alojamiento, cancela o da por finalizada una reserva o un contrato de alquiler en el que se ha quejado a Linetor sobre las condiciones de la vivienda en un plazo de 24 horas desde la fecha de entrada en el alojamiento.</li>
    </ul>
    </ul> 
    
    <hr>

    <p>12.7 Según esta cláusula no se pagará ninguna cantidad si el usuario o el proveedor de alojamiento ha ofrecido intencionadamente información falsa a Linetor en cualquier momento.</p>
    <p>12.8 Ninguna suma será reembolsable bajo esta cláusula si el usuario no se muda al alojamiento en la fecha indicada (Fecha de Entrada) o inmediatamente después.</p>
    <p>12.9 Linetor tendrá derecho, en cualquier momento y sin aviso previo, de inspeccionar o permitir inspeccionar a un tercero, el alojamiento al que se aplica la cláusula 12.</p>
    <p>12.10 El usuario reconoce que el servicio de segundo alquiler (y las obligaciones correspondientes de Linetor según esta cláusula 12) tiene el propósito de darle al proveedor de alojamiento la confianza de que el alojamiento estará ocupado durante el periodo correspondiente del contrato:</p>
    <ul>
    <li>(a) en consecuencia, esta cláusula 12 no exime al usuario de llevar a cabo sus obligaciones según estos términos o cualquier contrato de alquiler formalizado con el proveedor de alojamiento. El usuario deberá reembolsar a Linetor todas las cantidades pagadas por Linetor al proveedor de alojamiento según o en relación con esta cláusula 12 como resultado del abandono permanente (o afirmar querer abandonar) del alojamiento por parte del usuario durante el periodo del contrato.</li>
    <li>(b) si Linetor realiza cualquier pago, o si Linetor reclamara un pago según esta cláusula 12, el usuario deberá pagar a Linetor de forma inmediata tras la solicitud por escrito de Linetor una cantidad (en la moneda correspondiente) igual a la cantidad total de tal reclamación;</li>
    <li>(c) y si un certificado de Linetor con la cantidad de tal pago es prueba concluyente (excepto en caso de fraude) de que el pago ha sido realizado o exigido por Linetor y, en consecuencia, de acuerdo con esta cláusula 12, debe pagar el usuario. Todos los pagos del usuario deben realizarse a Linetor sin ningún tipo de deducción, compensación o retención.</li>
    </ul>

    <p>13. Programa de Selección de Linetor</p>
    <p>13.1 Linetor están dispuestos a ofrecer algunos proveedores de alojamiento la oportunidad de unirse a su Programa de Selección. Para que un proveedor de alojamiento para recibir el beneficio del Programa de Selección, Linetor tendrá que ponerse de acuerdo para proporcionar un servicio de este tipo al proveedor del alojamiento, que Linetor se comunicará a través de un programa de selección de correo electrónico y el proveedor de alojamiento tendrá que estar de acuerdo en ser miembro del Programa de Selección plazo de 4 días de la fecha de dicho Programa de Selección Email, sujeto a estas Condiciones de servicio que incluyen esta cláusula 13, haciendo clic en el botón correspondiente en el tablero del hospedero o la página de destino en el sitio web “aceptar”. Si el proveedor de alojamiento no hace esto, o Linetor retira su oferta antes acepta el proveedor de alojamiento, esta cláusula 13 no se aplicará. <br/><br/>

    13.2 La membresía como Proveedor de Alojamiento del Programa e Selección tendrá efecto desde la fecha en que el proveedor acepte la oferta de Linetor y finalizará en la Fecha Final del Programa de Selección:<br/><br/>

    (a) El Proveedor de Alojamiento no publicitará o aceptará ninguna reserva mediante otro canal que no sea la página web durante el Período de Bloqueo;<br/><br/>

    (b) Linetor podrá aceptar una reserva de alojamiento que incluya cualquier lapso de tiempo durante el Período de Bloqueo, incluyendo una reserva que finalizada después de que el Período de Bloqueo expire, y la Comisión de Linetor será calculada en los términos del Valor de la Reserva total.<br/><br/>

    (c) Linetor tendrá control exclusivo sobre la aceptación y el rechazo de todas las Solicitudes de reserva realizadas respecto al Alojamiento durante Período Bloqueado siempre que las reglas de la casa del Proveedor de Alojamiento (indicadas en el Sitio web en el momento de la Solicitud de Reserva) sean satisfactorias; y<br/><br/>

    (d) si el Proveedor de Alojamiento notifica a Linetor su deseo de retirarse del Programa de Selección o cancela una reserva aceptada durante el Programa de Selección, el Proveedor de Alojamiento pagará a Linetor una suma igual a la Comisión de Linetor que habría sido pagada si se hubiera aceptado una reserva durante el Programa de Selección por todo el Período Bloqueado. Además, la membresía del Proveedor de Alojamiento en el Programa de Selección se cancelará y el Período Bloqueado ya no se aplicará.<br/><br/>

    (e) El Propietario permite a Linetor, de forma unilateral, cambiar el precio de alquiler indicado (aumentar o disminuir) de una propiedad exclusiva. Sin embargo, si el propietario se convierte en elegible, esto no debería afectar la compensación acordada inicialmente en el ámbito del Programa de Selección;<br/><br/>

    (f) Si el inquilino cancela una reserva antes de la fecha de entrada (de acuerdo con los términos Linetor), Linetor mantendrá los fondos ya recibidos (deducidos de cualquier reembolso bajo la política de cancelación), manteniendo el compromiso de pago de la Compensación del Programa e Selección, en el caso
    de que las circunstancias establecidas en la cláusula 13.4 sean verificadas;<br/><br/>

    (g) Al final del Programa e Selección, el propietario debe proporcionar una factura a Linetor, haciendo referencia a la cantidad correspondiente a la compensación, en el caso
    de que se reúnan las condiciones para llevar a cabo esta transferencia. Linetor deberá pagar
    el importe de la factura dentro
    de los 30 días siguientes a la recepción
    de la factura correspondiente. <br/>
    13.3 El proveedor e alojamiento se asegurará e que todas las sumas cargadas en relación con el alojamiento durante el período bloqueado sean las mismas que las enumeradas en el sitio web en el momento en que Linetor envió el correo electrónico el programa e selección.<br/><br/>

    13.4 Linetor pagará al Proveedor e Alojamiento la Compensación el Programa e Selección dentro e los 15 días posteriores a la Fecha e Finalización el Programa e Selección si:
    <br/><br/>
    (a) ninguna solicitud e reserva es aceptada durante el Programa e Selección;
    <br/><br/>
    (b) todas las solicitudes e reserva que han sido aceptadas durante el Programa e Selección son cancelas por el usuario; o
    <br/><br/>
    (c) Linetor cancela la membresía el Proveedor e Alejamiento en el Programa e Selección y, en ese caso, la suma e la compensación podría ser reducida en relación a la cláusula 13.6.
    <br/><br/>
    (d) una solicitud e reserva es aceptada en el Programa e Selección, sin embargo, el valor e la renta obtenida por el arrendador en el primer mes el período bloqueado es menor que el valor e un mes e alquiler el alojamiento (basado en una sola la ocupación y el precio el alquiler en el Sitio Web en el momento Linetor envió el correo electrónico Programa e Selección).
    <br/><br/>
    13.5 En todas las circunstancias no establecidas en la cláusula 13.4, y si el Usuario o el Proveedor e Alojamiento han proporcionado conscientemente información falsa a Linetor en cualquier momento o no han corregido información relevante en el anuncio, la Compensación el Programa e Selección no será pagada.
    <br/><br/>
    13.6 Linetor tendrá el derecho e cancelar la membresía el Proveedor e Alojamiento en el Programa e Selección en cualquier momento. En caso e que Linetor lo haga, Linetor puede pagar al Proveedor e Alojamiento una Compensación el Programa e Selección reducida en línea con la política e cancelación "moderada" establecida en la cláusula 11.3 (c). En esta circunstancia, la 'Fecha e finalización el programa e selección' se aplicará como 'Fecha e mudanza'. Por ejemplo, 45 días antes e la fecha e finalización el programa e selección, Linetor podría terminar y solo pagarle al proveedor e alojamiento el 50% e la compensación el programa e selección.
    <br/><br/>
    13.7 Todas las solicitudes de reserva aceptadas durante el Programa de selección (a menos que se aplique la cláusula 13.4 (b)) se serán tratadas como cualquier otra reserva confirmada de acuerdo con estos Términos de Servicio, y la Comisión de Linetor se pagará en consecuencia.
    <br/><br/>
    13.8 Una política de cancelación moderada, como especifica la cláusula 11.3 (c) será aplicada a todas las reservas durante el Programa de Selección.
    <br/><br/>
    13.9 El proveedor de alojamiento acepta reunirse con el usuario en el alojamiento (o hacer que una persona apropiada se reúna con el usuario en el alojamiento) en la fecha y hora en que el usuario solicita mudarse al alojamiento.
    <br/><br/>
    13.10 Durante el Período de Bloqueo, el Proveedor de Alojamiento no podrá:
    <br/><br/>
    (a) mover a un Usuario del Alojamiento a otra propiedad que sea propiedad o esté gestionada por el Proveedor de Alojamiento (o cualquier persona o entidad conectada directa o indirectamente al Proveedor de Alojamiento) sin el consentimiento expreso de Linetor y del Usuario;
    <br/><br/>
    (b) anunciar, comercializar o aceptar una reserva en otra propiedad de alquiler de un tipo similar a la del Alojamiento que el Proveedor de Alojamiento administra por un precio de alquiler más bajo que el del Alojamiento, sin la previa autorización por escrito consentimiento de Linetor, que no debe ser rechazado o demorado injustificadamente; o
<br/><br/>
    (c) realizar cualquier acción conjunta, en acuerdo con un Usuario con la intención de permitir que el Proveedor de Alojamiento se beneficie, injustamente, del Programa de Selección.
    <br/><br/>
    13.11 El proveedor de alojamiento no permitirá la cancelación de una reserva pagada sin el consentimiento por escrito de Linetor. Para evitar dudas, si
    el proveedor de alojamiento acepta reembolsar a un Usuario aunque dicho Usuario no esté con derecho a uno, bajo la política de cancelación "moderada", entonces no se hará ni un pago de Compensación del Programa de Selección  <br/><br/>
    13.12 El Proveedor de alojamiento deberá notificar a Linetor cualquier queja recibida de un Usuario con respecto al Alojamiento de forma inmediata.<br/><br/>

    13.13 Linetor tendrá derecho, en cualquier momento y sin previo aviso, a inspeccionar o permitir que un tercero inspeccione cualquier Alojamiento al que se aplica esta cláusula 13.<br/><br/>

    13.14 El Proveedor de Alojamiento acepta que al hacer clic en el botón "aceptar" en el panel o en la página de inicio del sitio web, firma electrónicamente estos Términos de Servicio y, en particular, esta cláusula 13, y por lo tanto:<br/><br/>

    (a) el Proveedor de alojamiento estará vinculado integralmente a esta cláusula 13 mediante dicha firma electrónica; y<br/><br/>

    (b) la firma electrónica se considerará como prueba de la autoría e integridad de esta cláusula 13, otorgándole valor probatorio.<br/><br/>

    13.15 Linetor se reserva el derecho de cancelar la Membresía de Proveedores de Alojamiento del Programa de Selección de Linetor dentro de los 15 días siguientes a la fecha en la que el propietario acepta la oferta para unirse al Programa de Selección de Linetor. En esta situación, no se pagará ninguna compensación del Programa de Selección. <br/><br/>

    14. Tu Conducta <br><br>
    14.1 Al realizar el pago de una Reserva después de ser aceptado por el proveedor del alojamiento y autorizar el cargo por servicio (pago único), el primer pago del alquiler (y la fianza de reserva, si procede) en relación con el alojamiento elegido, el usuario acepta que no deberá evitar a Linetor intentando realizar (o realizando) una reserva con el proveedor de alojamiento directamente y notificando a Linetor que has decidido no realizar una reserva. <br/><br/>

    14.2 Al utilizar la página web y/o los servicios y/o al aceptar la presentación de un usario en relación al alojamiento elegido, el proveedor de alojamiento acepta que no evitará a Linetor intentando realizar (o realizando) una reserva directamente con el usuario y notificando a Linetor de que el ususario ha decidido no reservar.<br/><br/>

    14.3 Al descargar y/o utilizar la página web aceptas no subir, publicar, enviar por email o enviar o transmitir de otro modo cualquier material que contenga virus, troyanos, gusanos informáticos o cualquier otro código de ordenador, archivos o programas diseñados para interrumpir, destruir o limitar la funcionalidad de cualquier software o hardware de ordenador.<br/><br/>

    14.4 Aceptas y te comprometes a mantener confidenciales tu nombre de usuario y contraseña, a no difundir tu contraseña a otras personas y a no permitir que otras personas inicien sesión en la página web utilizando tu nombre de usuario y contraseña.
    <br/><br/>
    14.5 Aceptas no interferir con los servidores o las redes conectadas a la página web o violar alguno de los procedimientos, las políticas o los reglamentos de las redes conectadas a la página web, incluyendo estos términos de servicio. Asimismo, aceptas:
    <br/><br/>
    (a) no intentar realizar ingeniería inversa o vender, exportar, autorizar, modificar, copiar, distribuir o transferir la página web a terceros, o poner en peligro el correcto funcionamiento de la página web o de otro modo intentar extraer el código fuente del software (incluyendo las herramientas, los métodos, los procesos y la infraestructura) que permite o es la base de la página web;
    <br/><br/>
    (b) no intentar obtener acceso a secciones seguras de la página web para las que no tienes derechos de acceso;
    <br/><br/>
    (c) no intentar hacerte pasar por otra persona mientras utilizas los servicios o la página web;
    <br/><br/>
    (d) no mostrar un comportamiento vulgar, ofensivo, intimidatorio o inaceptable mientras utilizas la página web;
    <br/><br/>
    (e) no revender o exportar el software asociado a la página web;
    <br/><br/>
    (f) no utilizar la página web para generar anuncios no deseados o mensajes basura;
    <br/><br/>
    (g) no utilizar procesos automáticos o manuales para buscar y recopilar información de la página web o interferir de algún modo con el correcto funcionamiento de la página web.<br/><br/>

    
    15. Derechos de Linetor<br/><br/>
    15.1 Al darte acceso a la página web Linetor revertirá los siguientes derechos y, al acceder, navegar o de otro modo utilizar la página web confieres a Linetor (y aceptas que Linetor tenga) los siguientes derechos:<br/><br/>

    (a) el derecho de denegar o retirar por cualquier motivo y en cualquier momento (sin previo aviso) tu acceso a la página web según las leyes aplicables si, según su propio criterio violas o infringes cualquiera de estos términos de servicio;<br/><br/>

    (b) el derecho de suspender, modificar o deshabilitar tu perfil sin previo aviso y por cualquier motivo;<br/><br/>

    (c) el derecho de modificar o actualizar la página web, las tarifas, los métodos de facturación o estos términos de servicio de vez en cuando;<br/><br/>

    (d) el derecho de informar sobre ti a la policía u otro órgano judicial si Linetor cree según su criterio que tu conducta (ya sea al utilizar la página web, nuestros servicios o no) es ilegal.<br/><br/>

    16.Propiedad Intelectual <br/><br/>
    16.1 Linetor y/o el/los otorgante(s) de su(s) licencia(s) son los únicos dueños de la página web, que incluye cualquier software, dominios y contenido disponible a través de la página web. La página web está protegida por derechos de autor del Reino Unido e internacionales y otras leyes de propiedad intelectual.<br/><br/>

    16.2 Sin carácter limitativo, esto significa que no puedes vender, exportar, autorizar, modificar, copiar, distribuir o transferir la página web (o parte de ella) o cualquier material proporcionado a través de la web sin el consentimiento expreso previo y por escrito de Linetor.<br/><br/>

    16.3 Te otorgamos una licencia global libre de regalías, no exclusiva, intransferible y revocable para utilizar nuestros derechos de propiedad en los servicios y la página web únicamente hasta el punto necesario que te permita participar en el servicio. Cualquier uso no autorizado de la página web dará lugar en la finalización automática de la licencia limitada otorgada por Linetor. Linetor se reserva el derecho de finalizar la licencia limitada sin previo aviso y en cualquier momento después de un uso no autorizado de la página web por tu parte.<br/><br/>

    16.4 Linetor y sus gráficos, logotipos, iconos y los nombres del servicio relacionado con la página web son marcas registradas, no registradas o etiquetas comerciales de Linetor. No pueden utilizarse sin el consentimiento expreso previo y por escrito de Linetor.<br/><br/>

    16.5 Aceptas que Linetor es dueño de los derechos de autor de cualquier contenido, ya sea textual, gráfico, fotográfico o de vídeo que cree y publique en su página web y no debes vender, exportar, autorizar, modificar, copiar, distribuir o transferir este contenido a terceros.<br/><br/>

    16.6 Otorgas a Linetor una licencia global libre de regalías, no exclusiva, intransferible y revocable para utilizar tus derechos de propiedad intelectural solamente hasta el punto necesario que permita a Linetor ofrecerte servicios. Para mayor claridad, dicho uso puede incluir cosas como mostrar tu nombre y logotipo junto a una lista de alojamiento o poner un enlace a tu página web.
    <br/><br/>
    16.7 Además, otorgas a Linetor una licencia global libre de regalías, no exclusiva, intransferible y revocable durante el periodo de este acuerdo, para utilizar sus derechos de propiedad intelectual en tu nombre, logotipo y elementos de marca (tu «marca») con el propósito de promocionar el servicio mediante menciones en la página y en declaraciones públicas y material publicitario al hecho de que has participado en nuestros servicios, siempre que al hacerlo hagamos lo posible por garantizar que no hacemos nada que dañe la buena voluntar de tu marca.
    <br/><br/>
    16.8. Deberás reembolsar a Linetor bajo petición cualquier gasto o indemnización por daños y perjuicios que surja como resultado de, o en relación con, cualquier presunta (o real) infracción de la propiedad intelectual de terceros u otros derechos que surjan del uso por parte de Linetor de la información proporcionada a la página web por tu parte.
    <br/><br/>
    17. Seguridad
    Pese a que hemos puesto en marcha medidas técnicas y de organización razonables a nivel comercial para mantener seguros tu información personal y contenido de usuario ante usos no autorizados, no podemos garantizar que terceros no autorizados nunca superen dichas medidas. Aceptas proporcionar tu información personal y contenido de usuario bajo tu responsabilidad.<br/><br/>
    18. Comunicaciones Electrónicas<br/><br/>
    Al descargar y/o utilizar la página web y/o el material proporcionado a través de la página web aceptas recibir comunicaciones y avisos electrónicos de Linetor. Aceptas que cualquier aviso, acuerdo, divulgación u otra comunicación que te enviemos de forma electrónica cumplirá todos los requisitos legales, incluyendo el que obliga a que dichas comunications sean por escrito. Tienes el derecho de pedirnos que no procesemos tus datos personales para labores de marketing. Puedes ejercer tu derecho de evitar tal proceso marcando ciertas casillas en los formularios que utilizamos para recopilar tus datos. También puedes ejercer el derecho en cualquier momento contactando con nosotros en help.linetor.com. <br/><br/>

    19. Privacidad<br/><br/>
    Nos proporcionas información cuando te registras en la página web. También recopilamos información tuya (sobre el historial de uso y tus preferencias relativas a ciertos tipos de ofertas, por ejemplo) y de otros usuarios de la página web y de los servicios en general. Cualquier información que proporciones o que recopilemos cuando utilices la página web o los servicios estará sujeta a nuestra política de cookies, que puedes encontrar en cuyos términos han sido incorporados a estos términos de servicio.
    20. Uso de la Información Proporcionada<br/><br/>
    20.1 Aceptas que Linetor tiene la libertad de utilizar comentarios, información (excepto información personal) o ideas incluidas en cualquier comunicación que envíes a Linetor sin ningún tipo de compensación, reconocimiento o pago para cualquier fin, incluyendo (entre otras cosas) desarrollar, fabricar y anunciar productos y servicios y crear, modificar o mejorar los servicios y la página web u otros productos o servicios. Para que no quedp duda, Linetor considerará toda esa información no confidencial y abierta, y aceptas que tal información pueda ser utilizada por Linetor sin ningún tipo de restricción.<br/><br/>

    20.2 Aceptas que cualquier información que publiques en la página web sea información pública y que otros usuarios de la página web puedan ver y comentar dicha información. Puedes clasificar alguna información como pública y otra como privada. La información pública aparecerá en tu página de perfil.<br/><br/>

    20.3 Aceptas que Linetor puede volver a publicar y utilizar cualquier información, incluidos, sin carácter exhaustivo, textos e imágenes que publiques en la página. Nuestro uso de dicha información incluye, entre otros, la publicación de tu anuncio de alojamiento en otras páginas como OLX, Gumstree, Craigslist e Idealista. Aceptas que Linetor tiene derecho a no citarte como la fuente de dicha información y/o creador de dicho contenido. Sin embargo, normalmente intentamos añadir un enlace a tu página cuando publicamos contenido en páginas de terceros. Por el presente renuncias de forma irrevocable a todos los derechos morales de cualquier contenido que publiques en la página. Confirmas que tienes todos los derechos y permisos necesarios para compartir la información que pueda pertenecer a terceros y publicarla en la página.<br/><br/>

    21. Sin Límites de Garantía y Responsabilidad<br/><br/>
    21.1 Linetor ofrece los servicios y la página web tal y como son y sin ningún tipo de garantía o condición, ya expresa, implícita o legal. A menos que seas un consumidor, Linetor renuncia a cualquier garantía implícita de título, comercialización o idoneidad para un fin específico. Linetor renuncia de forma específica a cualquier garantía implícita de no violación. Linetor no asume responsabilidad por errores u omisiones en la página web o el suministro de servicios; fallos, retrasos o interrupciones en la página web; pérdidas o daños que surjan del uso de los servicios o la página web; cualquier conducta de los usuarios de los servicios o la página web; cualquier responsabilidad del proveedor de alojamiento según la Ley de Inmigración de 2114 surja de la situación de inmigración de cualquier usuario. Nos reservamos el derecho de ofrecer los servicios y la página web siguiendo nuestro propio criterio.<br/><br/>

    21.2 Esta cláusula (21.2) solo se aplica si eres un negocio y no un consumidor. Sujeta a la cláusula 21.4, Linetor, sus accionistas, directivos, ejecutivos, empleados o agentes no serán responsables ante ti de forma mancomunada o solidaria de:<br/><br/>

    (a) la pérdida de beneficios, negocio o ingresos;<br/><br/>

    (b) la pérdida o corrupción de datos, información o software;<br/><br/>

    (c) la pérdida de oportunidad de negocio;<br/><br/>

    (d) la pérdida de ahorros anticipados;<br/><br/>

    (e) la pérdida de buena voluntad;<br/><br/>

    (f) cualquier pérdida indirecta o significativa que surja de o en relación con los servicios y/o la página web o estos términos de servicio, ya estés o no informado de la posibilidad de daño.<br/><br/>

    21.3 Esta cláusula 21.3 se aplica solamente si eres consumidor. Si Linetor no cumple estos términos de servicios será responsable de la pérdida o el daño que sufras que sea el resultado predecible de la infracción de Linetor de estos términos o de negligencia, pero Linetor no será responsable de pérdidas o daño no predecibles. Se entiende por pérdida o daño predecible aquel que es una consecuencia obvia de nuestra infracción o si tanto tú como Linetor la han contemplado en el momento de formalizar este contrato. Linetor provee los servicios para uso privado y doméstrico. Linetor no será responsable ante ti por pérdidas de beneficio, pérdida de negocio, interrupción de negocio o pérdida de oportunidad de negocio.<br/><br/>

    21.4 Linetor no intenta excluir la responsabilidad por muerte o lesiones provocadas por nuestra negligencia o por fraude o representación fraudulenta por parte de Linetor.
    <br/><br/>
    21.5 De vez en cuando podrás utilizar o tener acceso a nuestros servicios, promociones y páginas web de terceros (incluyendo, entre otros, proveedores de alojamiento). Al utilizar o tener acceso a servicios, promociones y páginas web de terceros, aceptas estar vinculado por los términos de servicio de dichos terceros que regulen sus servicios, promociones y páginas web y por los presentes aceptas que no seremos responsables de proveer servicios, por la precisión de las promociones o el contenido de las páginas web que pertenezcan o sean gestionadas por terceros.
    <br/><br/>
    21.6 De vez en cuando Linetor tendrá promociones o incentivos que beneficien a los usuarios de nuestra página web, clientes potenciales y clientes. Linetor se reserva el derecho de modificar o eliminar las promociones en cualquier momento y puede cancelar cualquier beneficio o pago monetario que deba realizarse a personas que no hayan redimido parte de o todo el beneficio que se les debe según la promoción. Por favor, lee nuestra sección de preguntas frecuentes, donde puedes encontrar información sobre tales promociones.<br/><br/>

    21.7 Aceptas que Linetor no será responsable de la exactitud de la información publicada en la página web por los usuarios y no garantiza que la información publicada en la página web sea exacta, veraz o completa. Linetor excluye de forma específica la responsabilidad por pérdidas, agravios, daños psicológicos o daños que te afecten a ti o a un tercero como resultado de información incorrecta publicada en la página web.<br/><br/>

    21.8 Si una autoridad aplicable mantiene que cualquier parte de esta sección no es ejecutable, la responsabilidad quedará limitada al grado máximo que permita la ley aplicable.<br/><br/>

    22. Indemnización<br/><br/>
    Si eres un negocio, aceptas eximir a Linetor y sus empresas asociadas, accionistas, directivos, ejecutivos, empleados, agentes, socios comerciales y afiliados profesionales de toda responsabilidad por reclamaciones de terceros o derechos de acción, incluyendo tarifas razonables de abogados y gastos judiciales que surjan de forma directa o indirecta de tu uso de los servicios y/o la página web o tu violación de cualquier ley o derechos de terceros.<br/><br/>

    23. Disputas<br/><br/>
    23.1 Aceptas que estos términos de servicio y cualquier queja, disputa o controversia que surja de o en relación con estos términos de servicio o su contenido o formación (incluyendo disputas o quejas no contractuales), los servicios, la página web, la publicidad de Linetor o cualquier transacción relacionada entre tú y Linetor se regirán e interpretarán de conformidad con las leyes de Inglaterra.<br/><br/>

    23.2 Cualquier disputa o queja que surja de o en relación con dicho contenido (incluyendo disputas o quejas no contractuales) estará sujeta exclusivamente a la competencia de los tribunales de Inglaterra y Gales. Si es un consumidor y reside fuera de Inglaterra y Gales, no recibirá menos protección que aquella que su jurisdicción local le confiera.<br/><br/>

    23.3 Si usted es un consumidor y reside fuera de Inglaterra y Gales, no recibirá menos protección que la que le ofrece su jurisdicción local.<br/><br/>

    24. Finalización<br/><br/>
    Linetor podrá cambiar o interrumpir los servicios y/o la página web en cualquier momento y sin previo aviso. Nos reservamos el derecho de dar por finalizados estos términos de servicio por cualquier motivo y sin previo aviso, y estos términos de servicio finalizarán automáticamente si infringes cualquier término aquí establecido (sin perjuicio de nuestros derechos acumulados contra ti). En el caso de finalización, dejarás de utilizar los servicios y la página web de forma inmediata.<br/><br/>

    25. Miembros del Property Redress Scheme<br/><br/>

    25.2 Si desea presentar una queja sobre Linetor, primero debe seguir nuestro procedimiento interno para quejas, explicado en la sección Política de Devoluciones de nuestra página web. Si no se llega a una solución, puedes enviar la queja al Property Redress Scheme 8 semanas después de haber registrado la queja con Linetor.<br/><br/>

    26. General <br/><br/>
    26.1 Estos términos de servicio han sido aceptados por ti y por nosotros. Nadie tendrá derechos según o en relación con estos términos de servicio según la Ley de Contratos (derechos de terceros) de 1999.<br/><br/>

    26.2 Si un tribunal o una autoridad competente decide que cualquier término de estos términos de servicio no es válido, es ilícito o no ejecutable hasta cierto punto, dicho término (hasta cierto punto únicamente) deberá ser excluido de los términos restantes, que continuarán siendo válidos hasta el alcance total que permita la ley.
    <br/><br/>
    26.3 Los títulos son solamente para referencia y en ningún modo definen, limitan, interpretan o describen el alcance o el propósito de la sección.
    <br/><br/>
    26.4 Si no conseguimos hacer cumplir cualquiera de las disposiciones de estos términos de servicio esto no constituirá una renuncia de ello o de cualquier otra disposición y no te eximirá de la obligación de cumplir dicha disposición.
    <br/><br/>
    26.5 No se te permite asignar, transferir, cobrar, subcontratar o negociar de modo alguno todos o cualquiers de tus derechos según estos términos de servicio sin nuestro consentimiento expreso previo y por escrito.
    <br/><br/>
    26.6 Estos términos de servicio y política de cookies constituyen un acuerdo completo entre tú y Linetor y reemplaza y anula cualquier acuerdo, promesa, seguridad, garantía, declaración y acuerdo entre nosotros, ya sea por escrito o de forma oral, relacionado con el contenido.

</p><br/><br/>
</div>
</template>

<script>
export default {    
   
    data(){
        return {

        }
    }
}
</script>