<style>
.cookies_container{
       position: relative;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;
    color: black;
    margin-bottom: 100px;
    text-align: left;
}
.cookies_container h1{
    color: #007bff;
}
.cookies_container strong{
    color: black;
}
.cookies_container ul{
    color: black;
}
</style>
<template>
    <div class="cookies_container">
        <h1>Cookies</h1>
        <br/>
        <p>
            1. Uso de Cookies y Contador <br/><br/>
    1.1 Nuestra página utiliza cookies para diferenciarte de otros usuarios de la página web. Las cookies son archivos de texto almacenados de forma temporal o constante en el disco duro de tu ordenador. Las cookies se utilizan para verificar, rastrear la sesión y conservar información específica sobre el uso y los usuarios de la página web.<br/><br/>
    1.2 Los datos recopilados por las cookies son anónimos. Puedes eliminar todas las cookies que ya se encuentran en el disco duro de tu ordenador buscando archivos que contengan “cookies” y borrarlos. Además, si quieres que las cookies dejen de guardarse en tu ordenador puedes editar la configuración del navegador para bloquear las cookies. Desafortunadamente si bloqueas las cookies es posible que no puedas utilizar todas las funcionalidades de la página web. Más información sobre cookies en www.allaboutcookies.org.<br/><br/>
    1.3 Se podrán usar Cookies para saber cuándo el usuario visita Linetor, recordar sus preferencias y ofrecerle una experiencia personalizada de acuerdo a la configuración que haya establecido. Las Cookies también permiten que las interacciones del usuario con Linetor sean más rápidas y seguras. Además, las cookies nos permiten mostrar anuncios fuera de la página de Linetor.<br/><br/>
    Utilizamos Cookies con los siguientes propósitos:<br/><br/>
    Autentificación. Si estás conectado a tu cuenta de Linetor o eres un usuario habitual, las Cookies nos permiten mostrarte la información correcta y ofrecerte las mismas experiencias que en visitas anteriores, de acuerdo con tus preferencias.<br/><br/>
    Preferencias, configuración y servicios. Las Cookies nos proporcionan información sobre qué idioma prefieres y cuáles son tus preferencias de comunicación, lo que nos ayuda a rellenar los campos de los formularios de Linetor de forma más rápida.<br/><br/>
    Publicidad. Podemos utilizar Cookies para mostrarte anuncios fuera de la página de Linetor.<br/><br/>
    Rendimiento, análisis e investigación. Las Cookies permiten saber cuál es el rendimiento de nuestra página en distintos lugares. Asimismo, usamos Cookies para conocer, mejorar e investigar productos, características y servicios. Los servicios que utiliza Linetor que requieren el uso de Cookies son Google Analytics, Impact Radius, Inspectlet, Vero, Kissmetrics, VWO, el software propio de Linetor y Stripe.<br/><br/>
    1.4 También utilizamos contador de visitantes, que consiste en una pequeña línea de código software que representa una solicitud de imagen gráfica en una página o email. Puede que haya (o no) una imagen gráfica visible asociada al contador y con frecuencia la imagen está diseñada para mimetizarse con el fondo de la página o el email. Los contadores pueden utilizarse con diversos objetivos, como informar del tráfico de la página, contar visitantes únicos, controlar e informar de la publicidad y personalización. Los contadores utilizados en la página recopilan únicamente datos anónimos.<br/><br/>
    1.5 Para mejorar la página web y los servicios que ofrecemos, podemos utilizar (de forma temporal o constante) cookies, cookies de terceros permitidas, contadores y/o tecnologías para recopilar datos que no identifican a una persona. Estos datos nos ayudan a rastrear el comportamiento de navegación, crear ofertas específicas y personalizadas y controlar y registrar el número de visitas a la página y su uso.<br/><br/>
    1.6 Para comprender cómo las personas utilizan la página web y para fines de análisis de marketing y mejora de la calidad, podemos (nosotros y/o terceros que sean proveedores de servicio) recopilar, registrar, procesar y utilizar de forma anónima cierta información (incluyendo páginas de referencia/salida, tipo de plataforma, fecha/hora del registro de la información y datos como el número y la ubicación de los clics del ratón en una página concreta, movimientos del ratón, actividad de desplazamiento del cursor y las palabras de búsqueda que utilizas mientras visitas y utilizas la página web .<br/><br/>
    1.7 Si continúas utilizando la página web aceptas nuestro uso de las cookies.<br/><br/>
    1.8 Puedes encontrar más información sobre Cookies en los siguientes enlaces:<br/><br/>
    <ul>
        <li>www.allaboutcookies.org</li>
        <li>http://ec.europa.eu/ipg/basics/legal/cookies/index_en.html</li>
        <li>Publicidad Alianza Europea Digital Interactiva (UE)</li>
        <li> Internet Advertising Bureau (UE)</li>
        <li>Network Advertising Initiative</li>
    </ul>
    
 
    
   
 

        </p>
    </div>
</template>
<script>
export default {
    
    data() {
        return {

        }
    }
}
</script>